.card-container {
  margin-top: 1.4rem;
  margin-bottom: 4.0rem;
  padding: 2.0rem 2.0rem 2.0rem 2.0rem;
  /*width: 34.3rem;*/
  height: 21.6rem;
  border-radius: 16px;
  background-color: #F7F8F9;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  box-sizing: border-box;
}

.card-row {
  display: flex;
  flex-direction: row;
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.4rem;
  }
}

.card-title {
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  text-align: left;
  color: black;
}

.card-tag {
  margin-left: auto;
  width: 7.2rem;
  height: 2.5rem;
  background-color: rgba(0, 0, 0, 0.64);
  border-radius: 6px;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.5rem;
  text-align: center;
  color: white;
}

.card-profile-name {
  margin-right: 0.6rem;
  margin-bottom: 2.6rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  text-decoration: underline;
  color: var(--color-black800);
}

.card-company {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black800);
}

.card-description {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
}

.card-copy {
  margin-left: 0.6rem;
  margin-bottom: 1.7rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-nailet_green600);
}