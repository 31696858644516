.container {
  font-family: 'Pretendard';
  position: relative;
}

.text {
  font-size: 2.4rem;
  font-weight: 600;
  margin: auto 2rem;
  line-height: 3.36rem;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 5.6rem;
  margin-bottom: 10.2rem;
}

.confirm-container {
  display: flex;
  justify-content: space-between;
  margin: auto 2.4rem;
  font-size: 1.4rem;
  color: var(--color-black500);
  font-weight: 500;
  line-height: 1.68rem;
}

.confirm-title {
  display: flex;
  flex-direction: column;
  span {
    margin-top: 1.3rem;
  }
}
.confirm-data {
  display: flex;
  flex-direction: column;
  text-align: right;
  span {
    margin-top: 1.3rem;
  }
}

.info-container {
  display: flex;
  margin: auto 2rem;
  margin-bottom: 2.8rem;
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
  }
  ul {
    margin: 0;
  }
  li {
    list-style: decimal;
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--color-black400);
    line-height: 1.54rem;
  }
}

.button-container {
  width: 100%;
  position: fixed;
  bottom: 3.2rem;
}
@media screen and (min-width: 1000px) {
  .container {
    height: calc(100vh - 13rem);
  }
  .button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
  }
}
