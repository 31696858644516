.container {
  min-height: 100vh;
}

.menu-icon {
  img {
    width: 24px;
    height: 24px;
    margin-right: 2rem;
  }
}

.list-container {
  flex-grow: 1; /* 중간의 div가 남은 공간을 모두 차지하도록 함 */
  overflow-y: auto;
  padding-bottom: 5.6rem;
}

.title-text {
  margin-top: 1.2rem;
  margin-bottom: 1.1rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 3.08rem;
  color: black;
}

.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 2.2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  margin-left: 1.6rem;
  margin-bottom: 3.2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
}

.sheet-card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 0.8rem;
}

.sheet-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-content {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #1d1d1e;
  white-space: pre-wrap;
}

.sheet-button-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 5px;
}

@media screen and (min-width: 1000px) {
  .container {
    min-height: calc(100vh - 5.7rem);
    overflow: hidden;
  }
  .sheet-button-container {
    width: 100%;
  }
}
