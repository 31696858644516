.container {
  font-family: 'Pretendard';
}

.title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.52rem;
  margin: 1.2rem 1.6rem;
}

.detail-container {
  display: flex;
  flex-direction: column;
  margin: auto 1.6rem;
  padding: 1.6rem;
  padding-bottom: 0.8rem;
  border: 1px solid var(--color-black_transparent_12);
  border-radius: 12px;
  margin-bottom: 0.8rem;
  span {
    font-size: 1.3rem;
    margin-right: 0.8rem;
  }
}

.detail-title {
  position: relative;
  display: flex;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.82rem;
  margin-bottom: 0.8rem;
  span {
    font-size: 1.3rem;
  }
  .detail-text {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.82rem;
    color: var(--color-white600);
  }
}
.sale-container {
  display: flex;
  flex-direction: column;
  margin: auto 1.6rem;
  padding: 1.6rem;
  padding-bottom: 0.8rem;
  border: 1px solid var(--color-black_transparent_12);
  border-radius: 12px;
  margin-bottom: 0.8rem;
  .detail-title {
    justify-content: space-between;
    font-size: 1.5rem;
  }
  .detail-text {
    color: var(--color-white600);

    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.82rem;
  }
  .detail-text-black {
    color: var(--color-black900);
  }
}
.profit-container {
  margin: auto 1.6rem;
  padding: 1.6rem;
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--color-white600);
  line-height: 1.82rem;
}
@media screen and (min-width: 1000px) {
  .button-container{
    min-width: 320px;
    width: 600px;

  }
 

}