.container {
  font-family: 'Pretendard';
  min-height: 100vh;
}

.main-container {
  background-color: var(--color-white100);
  padding: 2.8rem 2rem;
}
.main-item {
  padding: 2rem;
  background-color: #fff;
  margin-bottom: 2.4rem;
  border-radius: 20px;
}
.main-info {
  margin-top: 0.75rem;
  display: flex;
  align-items: flex-start;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--color-black_transparent_40);
  white-space: pre-wrap;
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
  }
}

.main-text {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.96rem;
}

.main-title {
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 4.32rem;
}

.main-date {
  text-align: right;
  color: var(--color-black_transparent_40);
  font-size: 1.2rem;
  line-height: 1.44rem;
}

.tab-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid var(--color-white200);
}

.tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #999dab;
  padding-top: 1.6rem;
}

.tab-item-selected {
  color: var(--color-black800);
}

.tab-line {
  margin-top: 0.7rem;
  width: 16.7rem;
  height: 3px;
  background-color: transparent;
}

.tab-line-selected {
  background-color: var(--color-black800);
}

.item-filter {
  margin: 1.8rem 2rem;
  margin-bottom: 1.2rem;
  width: 7.2rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  color: var(--color-white700);
  cursor: pointer;
  img {
    margin: 0;
    width: 1.6rem;
    height: 1.6rem;
    filter: invert(69%) sepia(4%) saturate(632%) hue-rotate(194deg) brightness(90%) contrast(93%);
  }
}

.sheet-terms-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pretendard';

  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 2.2rem 1.6rem 1.4rem 1.6rem;
}

.sheet-terms-content {
  margin-bottom: 1.6rem;
  font-family: 'Pretendard';
}

.date-select {
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
  margin-bottom: 1.8rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.96rem;
  img {
    width: 2rem;
    height: 2rem;
    margin-right: 0.6rem;
  }
}
.info-container {
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.96rem;
  img {
    width: 2rem;
    height: 2rem;
    margin-right: 0.6rem;
  }
  .info-box {
    text-align: center;
    width: 9rem;
    height: 3rem;
    line-height: 3rem;
    margin-right: 0.8rem;
    background-color: var(--color-green600);
    border-radius: 6px;
    font-size: 1.3rem;
    color: white;
    font-weight: 500;
  }
  .info-text {
    color: var(--color-black500);
  }
}

.info-container:nth-child(2) > .info-box {
  width: 9rem;
  height: 3rem;
  line-height: 3rem;
  margin-right: 0.8rem;
  background-color: var(--color-green70);
  border-radius: 6px;
  color: var(--color-green700);
}
.info-container:nth-child(3) > .info-box {
  width: 9rem;
  height: 3rem;
  line-height: 3rem;
  margin-right: 0.8rem;
  background-color: var(--color-red50);
  border-radius: 6px;
  color: var(--color-red500);
}
.info-container:nth-child(4) > .info-box {
  width: 9rem;
  height: 3rem;
  line-height: 3rem;
  margin-right: 0.8rem;
  background-color: var(--color-white100);
  border-radius: 6px;
  color: var(--color-black500);
}
.info-container:nth-child(5) > .info-box {
  width: 9rem;
  height: 3rem;
  line-height: 3rem;
  margin-right: 0.8rem;
  background-color: var(--color-white500);
  border-radius: 6px;
  color: var(--color-white0);
}

.item-flex {
  display: flex;
  .item-filter:first-child {
    margin-right: 0;
  }
  .item-filter:last-child {
    margin-left: 0.8rem;
  }
}

.issue-box {
  margin-bottom: 0.7rem;
}

.issue-item {
  display: flex;
  align-items: center;
  margin: auto 2rem;
  bottom: 0rem;
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
    margin-right: 0.15rem;
  }
  span {
    font-size: 1.1rem;
    font-weight: 500;
    color: #808080;
  }
  strong {
    font-weight: 500;
    text-decoration: underline;
  }
}

@media screen and (min-width: 1000px) {
  .container {
    min-height: 100vh;
  }
}
