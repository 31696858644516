.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.8rem;
  border-radius: 12px;
  border-bottom: 3px solid #edf0f3;
  box-sizing: border-box;
}

.container-focused {
  border-color: #00ca75;
}

.container-error {
  border-color: #ff6b6b;
}

.input-field::-webkit-input-placeholder {
  color: #9a9ca2;
}

.input-field::-moz-placeholder {
  color: #9a9ca2;
}

.input-field:-ms-input-placeholder {
  color: #9a9ca2;
}

.input-field::placeholder {
  color: #9a9ca2;
}

img {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 1.4rem;
}

.prefix {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 2.2rem;
  color: var(--color-black800);
}

.input-field {
  width: 100%;
  border: none;
  outline: none;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 2.2rem;
  color: var(--color-black800);
}
