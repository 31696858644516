.container {
  font-family: 'Pretendard';
  height: 100vh;
}

.title-text {
  margin-left: 2rem;
  margin-top: 0.9rem;
  margin-bottom: 2.4rem;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
}

.sub-text {
  color: var(--color-black200);
  font-size: 1.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  white-space: pre-wrap;
  margin-bottom: 1rem;
}

.image {
  text-align: center;
}

.image img {
  width: 16rem;
  height: 16rem;
}

.information-container {
  margin: auto 2rem;
  padding: 1.6rem 2rem;
  background-color: var(--color-white100);
  border-radius: 12px;
}
.information-text {
  font-size: 1.4rem;
  color: var(--color-black500);
  font-weight: bold;
}
.information-sub-text {
  font-size: 1.5rem;
  color: var(--color-black500);
  font-weight: bold;
  margin-top: 0.5rem;
}
.button-container {
  position: fixed;
  width: 100%;
  bottom: 3.2rem;
}
@media screen and (min-width: 1000px) {
  .container {
    height: calc(100vh - 8rem);
    padding-bottom: 0;
  }

  .button-container {
    position: sticky;
    bottom: 0;
    min-width: 320px;
    width: 100%;
  }
}
