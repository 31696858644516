.search-result-item {
  margin-top: 0.8rem;
}

.search-history {
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}

.search-history-title-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 4.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #000000;
}

.search-history-item-container {
  margin-bottom: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: var(--color-black500);
}

.search-history-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-history-clear {
  width: 2rem;
  height: 2rem;
  margin-left: auto;
}

.search-result-item {
  margin-left: 1.6rem;
  height: 4rem;
  display: flex;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: var(--color-black500);
}

.search-highlight-text {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: #00b76a;
  white-space: pre;
}

.search-general-text {
  white-space: pre;
}
