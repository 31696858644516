.container {
  font-family: 'Pretendard';
  padding-bottom: 8rem;
}

.tab {
  display: flex;
  margin: 2.4rem 2rem;
  justify-content: space-between; /* 탭을 양쪽으로 분배 */
}

.title {
  flex: 1; /* 각 탭이 동일한 비율로 분배되도록 설정 */
  font-weight: 600;
  font-size: 1.6rem;
  text-align: center; /* 텍스트 중앙 정렬 */
  cursor: pointer; /* 커서 스타일 */
  padding-bottom: 1.2rem;
}

.title.active {
  color: var(--color-black900); /* 활성화된 탭 색상 */
  border-bottom: 3px solid; /* 아래 경계선 */
}

.title.inactive {
  color: var(--color-white700); /* 비활성화된 탭 색상 */
  border-bottom: 2px solid; /* 아래 경계선 */
}

.info-container {
  line-height: 2.52rem;
  .text {
    margin: auto 2rem;
  }
  .sub-text {
    line-height: 1.96rem;
    margin: auto 2rem;
  }
}
.text {
  font-size: 1.8rem;
  font-weight: 600;
  white-space: pre-wrap;
}
.sub-text {
  color: var(--color-black200);
  font-size: 1.4rem;
  font-weight: 600;
}
.info-sub-container {
  margin: 3rem auto;
  padding-bottom: 2.8rem;
  border-bottom: 8px solid var(--color-white50);

  .info-subject-container {
    margin: auto 2rem;
    margin-top: 2rem;
  }
}
.info-meeting-container {
  line-height: 2rem;
  .text {
    margin: auto 2rem;
  }
  .sub-text {
    margin: auto 2rem;
    margin-bottom: 1.6rem;
  }
  border-bottom: 8px solid var(--color-white50);
  padding-bottom: 0.8rem;
}
.info-help-container {
  line-height: 2rem;
  margin-top: 2.4rem;
  .text {
    margin: auto 2rem;
    margin-bottom: 1.6rem;
  }
  border-bottom: 8px solid var(--color-white50);
  padding-bottom: 0.8rem;
}

.info-notice-container {
  margin-top: 2.8rem;
  .text {
    margin-left: 2rem;
    font-size: 1.6rem;
    font-weight: bold;
  }
}
.list-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  padding: 2rem;
  padding-top: 1.4rem;
  line-height: 2rem;
}
.list-title:last-child {
  padding-top: 0;
}
.list-title li {
  margin: auto 2rem;
  font-size: 1.3rem;
  color: var(--color-black200);
  font-weight: normal;
  margin-top: 0.6rem;
  line-height: 2.08rem;
}
.info-notice {
  font-size: 1.3rem;
  font-weight: normal;
  color: var(--color-black200);
}

.info-subject-container {
  display: flex;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black900);
  margin: auto 2rem;
  padding: 1.4rem;
  background-color: var(--color-white50);
  margin-bottom: 1rem;
  border-radius: 0.8rem;
  img {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.info-recommend-container {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--color-black900);
  margin: auto 2rem;
  padding: 1.4rem;
  line-height: 1.96rem;
  background-color: var(--color-white50);
  border-radius: 1.2rem;
  margin-bottom: 1.4rem;
  img {
    margin-bottom: 0.8rem;
    width: 2.8rem;
    height: 2.8rem;
  }
}

.sheet-terms-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pretendard';
  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 1.6rem 2.2rem 0 1.6rem;
}

.sheet-terms-content {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.button-container {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  width: 100%;
  height: 8rem;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  place-items: center;
}
.mate-button-container {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  width: 100%;
  height: 8rem;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  place-items: center;
  gap: 1.2rem;
}

/* 후기 */

.review-number-container {
  display: flex;
  align-items: center;
  margin: auto 2rem;
  gap: 0.5rem;
}
.review-number-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background-color: var(--color-white50);
  border-radius: 16px;
  text-align: center;
  font-size: 2.8rem;
  font-weight: bold;
  height: 9.2rem;
}
.review-number-type {
  font-size: 1rem;
  color: var(--color-green600);

  margin: 0.2rem auto;
  display: flex;
  align-items: center;
}
.type-line {
  width: 0.1rem;
  height: 0.9rem;
  background-color: var(--color-white200);
  margin: auto 0.2rem;
}
.review-number-text {
  font-size: 1.2rem;
  color: var(--color-black500);
  font-weight: 600;
}
.rating-container {
  display: flex;
  justify-content: center;
  margin: 0.2rem auto;
}

.review-number-rating {
  img {
    width: 1.4rem;
    height: 1.4rem;
    margin: 0;
    margin: auto 0.2rem;
  }
}

.line {
  width: 0.1rem;
  height: 0.9rem;
  background-color: var(--color-white200);
  margin: auto 0.2rem;
}

@media screen and (min-width: 1000px) {
  .container {
    padding: 0;
  }
  .content-container {
    overflow-y: scroll;
    height: calc(100vh - 50rem);
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;
  }

  .button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
  }
  .mate-button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
  }
  .review-container {
    min-height: 60vh;
  }
}
