.rating-container {
  font-family: "Pretendard";
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: var(--color-white50);
  margin: 1rem 1.6rem;
  border-radius: 14px;
  padding: 2rem;

}

.star {
  width: 2.5rem; /* 별 크기 조절 */
  height: 2.4rem; /* 별 크기 조절 */
  cursor: pointer; /* 클릭 가능하게 보이도록 설정 */
  filter: grayscale(100%); /* 기본 회색 */
  margin-right: 1.2rem;
  margin-left: 1.2rem;
}

.star.selected {
  filter: grayscale(0%); /* 선택 시 원래 색상 */
}

.rating-container p {
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 1.4rem;
}