.container {
  font-family: 'Pretendard';
}

.title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.52rem;
  margin: 1.2rem 1.6rem;
}

.detail-container {
  display: flex;
  flex-direction: column;
  margin: auto 1.6rem;
  padding: 1.6rem;
  padding-bottom: 0.8rem;
  border: 1px solid var(--color-black_transparent_12);
  border-radius: 12px;
  margin-bottom: 0.8rem;
}

.detail-title {
  display: flex;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.82rem;
  margin-bottom: 0.8rem;
  span {
    margin-right: 0.8rem;
  }
  .detail-text {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.82rem;
    letter-spacing: -1.1px;
    color: var(--color-white600);
  }
}

.sale-container {
  display: flex;
  flex-direction: column;
  margin: auto 1.6rem;
  padding: 1.6rem;
  padding-bottom: 0.8rem;
  border: 1px solid var(--color-black_transparent_12);
  border-radius: 12px;
  margin-bottom: 0.8rem;
  .detail-title {
    justify-content: space-between;
    font-size: 1.5rem;
  }
  .detail-text {
    color: #000;
    font-weight: bold;
    font-size: 1.5rem;
  }
}
.profit-container {
  display: flex;
  flex-direction: column;
  margin: auto 1.6rem;
  padding: 1.6rem;
  padding-bottom: 0.8rem;
  border: 1px solid var(--color-black_transparent_12);
  border-radius: 12px;
  margin-bottom: 0.8rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--color-white600);
  white-space: pre-wrap;
  .detail-title {
    justify-content: space-between;
    font-size: 1.5rem;
    color: #000;
  }
  .detail-title:first-child {
    border-bottom: 1px solid var(--color-black_transparent_12);
    padding-bottom: 1rem;
  }
  .detail-title:last-child {
    border-bottom: 1px solid var(--color-black_transparent_12);
    padding-bottom: 1rem;
  }
  .detail-text {
    color: #000;
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.error-message {
  font-size: 2rem;
  color: var(--color-black400);
  text-align: center;
}
