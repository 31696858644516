.container {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 0rem;
  }
}
.no-bgColor {
  background-color: #fff;
}
.bgColor {
  background-color: var(--color-white100);
  /* 보더 none */
  border: none;
}

.container-focused {
  border-color: #a6b9fb;
}

.input-field::-webkit-input-placeholder {
  color: #9a9ca2;
}

.input-field::-moz-placeholder {
  color: #9a9ca2;
}

.input-field:-ms-input-placeholder {
  color: #9a9ca2;
}

.input-field::placeholder {
  color: #9a9ca2;
}

.text {
  width: 100%;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  color: #9a9ca2;
}

.text-active {
  color: #1d1d1e;
}
.active {
  opacity: 0.4;
  pointer-events: none;
}
