.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.label-container {
  margin-top: 3.2rem;
  margin-bottom: 4.0rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.0rem;
  line-height: 2.8rem;
  color: var(--color-black800);
}

.complete-image {
  text-align: center;
  margin-bottom: 4.0rem;

  img {
    width: 16.0rem;
    height: 16.0rem;
    margin: 0;
  }
}

.title {
  margin-left: 2.0rem;
  margin-bottom: 0.8rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #87888D;
}

.link-text {
  margin-left: 2.0rem;
  margin-bottom: 3.6rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: #000000;
  text-decoration: underline;
}

.button-container {
  margin-top: 6.1rem;
  background-color: white;
  display: flex;
}