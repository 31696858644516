.conatiner {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.title-text {
  margin-top: 0.8rem;
  margin-bottom: 2.4rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.36rem;
  color: var(--color-black800);
  white-space: pre-wrap;

  strong {
    font-weight: 600;
    color: #00b76a;
  }
}

.price-description {
  margin-top: 0.8rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: #848691;
}

.reference-container {
  margin-top: auto;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 12px;
  border: 1px solid #e9ecef;
  border-radius: 16px;
}

.reference-reason-title {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: #848691;
}

.reference-reason-content {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: #848691;
  white-space: pre-wrap;
}

ul {
  padding-left: 1.7rem;
}

.button-container {
  width: 100vw;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: 2.8rem;
  margin-bottom: 3.2rem;
  box-sizing: border-box;
  background-color: white;
}

.sheet-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-title {
  margin-left: 2rem;
  margin-bottom: 1.4rem;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-content-container {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.2rem;
  padding: 2.4rem 1.6rem 2rem 1.6rem;
  border-radius: 16px;
  background-color: #f7f8f9;
}

.sheet-content-text {
  margin-bottom: 2rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.92rem;
  color: var(--color-black500);
}

.sheet-content-result-text {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: #00b76a;
}

.sheet-content-divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
  margin-top: 0.7rem;
  margin-bottom: 1.6rem;
}

.sheet-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  margin-bottom: 1.3rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black800);
}

.sheet-content-description-text {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2.4rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: #87888d;
  white-space: pre-wrap;
}

@media screen and (min-width: 1000px) {
  .button-container {
    min-width: 320px;
    width: 100%;
  }
}
