.container {
  font-family: 'Pretendard';
}
.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin: auto 2rem;
  margin-top: 2.4rem;

}

.select-container {
  margin: auto 2rem;
  img{
    margin: 0;
    margin-right: 0.6rem;
  }
}

.select-item {
  display: flex;  
  align-items: center;
  position: relative;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1.6rem auto;
  font-weight: 500;
  span {
    white-space: pre-wrap;
  }

}

.select-item-line {
  display: flex;  
  align-items: center;
  position: relative;
  font-size: 1.2rem;
  line-height: 1.44rem;
  margin: 1.6rem auto;
  font-weight: 500;
  border-top: 1px solid var(--color-black_transparent_6);
  padding-top: 1.1rem;
}

.link {
  position: absolute;
  right: 0;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.44rem;
  text-decoration: underline;
  color: var(--color-white700);
}
.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  height: 4.14rem;
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black800);
}

.sheet-terms-button-container {
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16)); 
  box-sizing: border-box;  
}

.sheet-terms-button {
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 12px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background: var(--color-green600);
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  color: white;
}
