.alarm-container {
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  padding-top: 1rem;
  padding-bottom: 1.2rem;
  /* background-color: var(--color-green500); */
}

.alarm-top {
  display: flex;
  align-items: center;
  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.6rem;
  }
}
.alarm-type,
.alarm-date {
  font-size: 1.2rem;
  color: var(--color-white700);
}
.alarm-item {
  display: flex;
  flex-direction: column;
}
.flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.alarm-text,
.alarm-link {
  margin-left: 2.4rem;
}
.alarm-text {
  font-size: 1.4rem;
  color: var(--color-black500);
  font-weight: 600;
  line-height: 1.96rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.alarm-link {
  font-size: 1.2rem;
  color: var(--color-green500);
  font-weight: bold;
  line-height: 1.44rem;
}
.alarm-text {
  white-space: pre-wrap;
}
