.card-container {
  display: flex;
  flex-direction: column;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  margin-bottom: 1.2rem;
  padding-top: 2rem;
  padding-bottom: 1.6rem;
  border-radius: 16px;
  border: 2px solid #e9ecef;
}

.card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-thumbnail {
  margin-left: 2rem;
  margin-right: 0.6rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.card-product-name {
  margin-right: 0.6rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: black;
  white-space: pre-wrap;
}

.card-review {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-star-icon {
  margin-right: 0.2rem;
  width: 1.6rem;
  height: 1.6rem;
}

.card-review-text {
  margin-right: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
}

.card-description-text {
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
}

.card-price-change-text {
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-green500);
}
