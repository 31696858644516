.conatiner {
    display: flex;
    flex-direction: column;
  }
  
  .title-text {
    margin-top: 0.8rem;
    margin-left: 2.0rem;
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 2.0rem;
    line-height: 2.8rem;
    color: var(--color-black800);
  }
  
  .title-sub-text {
    margin-bottom: 3.2rem;
    margin-left: 2.0rem;
    font-family: 'Pretendard';
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.96rem;
    color: #848691;
    white-space: pre-wrap;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-left: 2.0rem;
    padding-right: 2.0rem;
    gap: 1.6rem;
    box-sizing: border-box;
    background-color: white;
  }

  .description {
    margin-top: 1.6rem;
    margin-left: 2.0rem;
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.96rem;
    color: #00B76A;
  }