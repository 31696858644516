.container {
  font-family: 'Pretendard';
}

.welcome-text {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;
  margin: auto 2rem;
  margin-top: 2.4rem;
}

.welcome-image {
  text-align: center;
  img {
    width: 16rem;
    height: 10.8rem;
  }
  margin: 3.1rem auto;
}
.welcome-list {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1rem;
  margin: auto 2rem;
  position: relative;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    padding-left: 2.6rem;
    margin-top: 1.6rem;
  }
  img:first-child {
    padding-top: 0;
  }
  img {
    position: absolute;
    width: 2rem;
    height: 2rem;
    padding-top: 1.6rem;
  }
  strong {
    font-weight: 500;
    text-decoration: underline;
  }
}

.info {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.96rem;
  white-space: pre-wrap;
  color: #848691;
  margin-top: 1.05rem;
}
.button-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-top: 1.1rem;
  padding-bottom: 3.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
  /* filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16)); */
}
