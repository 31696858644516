.card-container {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    margin-bottom: 4rem;
    padding-top: 2.0rem;
    padding-bottom: 1.2rem;
    /*width: 34.3rem;*/
    /*height: 16.1rem;*/
    border-radius: 16px;
    border: 2px solid #E9ECEF;
  }
  .no-bgColor {
    background-color: #fff;
  }
  .bgColor {
    background-color: var(--color-white50);
    /* 보더 none */
    border: none;
  }
  
  .card-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .card-thumbnail {
      width: 5.6rem;
      height: 5.6rem;
      margin-right: 0.4rem;
    }
  }
  
  .card-thumbnail {
    margin-left: 1.5rem;
    margin-right: 0.8rem;
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.05);
    object-fit: cover;
  }  
  
  .card-meet-info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .card-meet-product-name {
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.24rem;
    color: black;
  }
  
  .card-tag {
    margin-left: auto;
    margin-right: 2.0rem;
    padding-left: 8px;
    padding-right: 8px;
    height: 2.5rem;
    background-color: rgba(0, 0, 0, 0.64);
    border-radius: 6px;
    font-family: 'Pretendard';
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.5rem;
    text-align: center;
    color: white;
  }
  
  .card-profile-name {
    margin-right: 0.6rem;
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.96rem;
    color: var(--color-black800);
  }
  .card-profile-name.underline {
    text-decoration: underline;
  }
  
  .card-profile-name.no-underline {
    text-decoration: none;
  }
  
  .card-company {
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.96rem;
    color: var(--color-black800);
  }
  
 .card-container img {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.2rem;
  }
  
  .card-description {
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.96rem;
    color: var(--color-black500);
  }