.container {
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 1.6rem;
    color: #FFFFFF;
}