.commentContainer {
  margin: auto 1.6rem;
  margin-top: 0.5rem;
  margin-bottom: 2.4rem;
}

.group {
  display: flex;
  align-items: center;
}

.iconGroup {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}
.nickname {
  margin-right: 0.8rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.68rem;
}
.information {
  font-size: 1.2rem;
  line-height: 1.44rem;
  color: var(--color-white800);
  font-weight: 600;
}
.article {
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.95rem;
  color: var(--color-black700);
  white-space: normal; /* 기본 줄바꿈 처리 */
  word-wrap: break-word; /* 긴 단어가 줄을 넘어가지 않도록 처리 */
  word-break: break-word; /* 긴 단어가 줄바꿈 없이 넘어가지 않도록 처리 */
}
.mention {
  color: var(--color-green600);
  margin-right: 0.5rem;
}
.iconwrapper {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-white800);
  margin-top: 1.2rem;
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
    margin-right: 0.2rem;
  }
}
.sheet-terms-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pretendard';

  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 2.2rem 1.6rem 1.4rem 1.6rem;
}

.sheet-terms-content {
  font-family: 'Pretendard';
}
.button-container {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 2rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin: auto 1.6rem;
  gap: 0.5rem;
}
.text {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  margin: auto 1.6rem;
  margin-bottom: 1.2rem;
}

.sheet-terms-content {
  font-family: 'Pretendard';
}

.report-select {
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.96rem;
  padding: 0.4rem 0;

  img {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 24px;
}

.popup-image {
  margin: 0 auto;
  width: 74px;
  height: 91px;
}

.popup-title {
  margin-bottom: 6px;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: var(--color-black900);
}

.popup-content {
  margin-bottom: 20px;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #888b93;
  white-space: pre-wrap;
}

.popup-button {
  width: 180px;
  height: 42px;
  background-color: #00b76a;
  border-radius: 24px;
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 42px;
  color: #ffffff;
}
