.container {
  display: flex;
  flex-direction: column;
  font-family: 'Pretendard';
  margin-bottom: 4rem;
  position: relative;
}

.detail-top-btn {
  position: fixed;
  z-index: 999;
  top: 1rem;
  right: 2rem;
  color: var(--color-green800);
  font-size: 1.5rem;
}

.detail-container {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}

.label-container {
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  margin-top: 0.6rem;
}

.detail-title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 2.8rem;
  margin-bottom: 1.2rem;
}
.detail-flex {
  display: flex;
  align-items: center;
}

.detail-rating-text {
  font-size: 1.2rem;
  font-weight: 600;
}

.detail-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.detail-container span {
  color: var(--color-green800);
}

.profile-image {
  width: 4.4rem;
  height: 4.4rem;
  margin-right: 0.8rem;
  object-fit: cover;
  border-radius: 50px;
}

.detail-text-container {
  font-size: 1.5rem;
  padding: 1.6rem;
  border: 1.5px solid var(--color-green300);
  background-color: var(--color-white50);
  border-radius: 12px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.detail-line {
  width: 100%;
  height: 0.1rem;
  background-color: var(--color-black_transparent_6);
  margin-top: 2.8rem;
}

.button-container {
  margin-left: -1.6rem;
  margin-right: -1.6rem;
}

.rating-container {
  img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.2rem;
  }
}
