.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 4.8rem;
}

.form-container {
  flex: 1;
  overflow-y: auto;
  box-sizing: box;
}

.button-container {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  position: fixed;
  bottom: 0px;
}

.label-container {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black800);
}

.tab-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #999dab;
}

.tab-item-selected {
  color: var(--color-black800);
}

.tab-line {
  margin-top: 0.7rem;
  width: 16.7rem;
  height: 3px;
  background-color: transparent;
}

.tab-line-selected {
  background-color: var(--color-black800);
}

.tab-all-line {
  margin-bottom: 2.4rem;
  width: 100%;
  height: 1px;
  background-color: #e9ecef;
}

.faq-title {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.24rem;
  margin-bottom: 2.8rem;
}

.faq-sub-title {
  margin-top: 4rem;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.24rem;
  margin-bottom: 2.8rem;
}

.faq-item {
  /* margin-left: 1.6rem;
  margin-right: 1.6rem; */
  margin-bottom: 2.95rem;
  /* height: 4.2rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: var(--color-black800);

  img {
    margin-right: 0.8rem;
  }
}

.faq-container {
  font-family: 'Pretendard';
  margin: auto 1.6rem;
}

.faq-more-container {
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 24px;
  background-color: var(--color-white50);
  border-radius: 12px;
  color: var(--color-black500);

  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.4rem;
  }
}

.faq-more-text {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1rem;
  white-space: pre-wrap;
  margin-bottom: 2.1rem;
}

.faq-more-link {
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: underline;
  line-height: 2.1rem;
  margin-bottom: 2.1rem;
  display: flex;
  align-items: center;
}

.result-item-selected {
  color: #00b76a;
}

.cs-container {
  /* margin: auto 1.6rem; */
  font-family: 'Pretendard';
}

.cs-sub-title {
  margin: auto 1.6rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1rem;
  white-space: pre-wrap;
}

.cs-mail-container {
  .faq-more-container {
    margin: 4.8rem 1.6rem;
  }
}

.cs-title {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.24rem;
  margin: auto 1.6rem;
  margin-top: 2.8rem;
}

.cs-subject {
  display: flex;
  margin: 2.8rem 1.6rem;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1rem;
  img {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.cs-mail-title {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.24rem;
  margin: auto 1.6rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 1000px) {
  .container {
    margin-bottom: 0;
  }
  .button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
  }
}
