.container {
  font-family: 'Pretendard';
}

.text {
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-black200);
  line-height: 1.68rem;
  margin: auto 2rem;
  margin-top: 2.4rem;
  margin-bottom: 0.7rem;
}

.button-container {
  width: 100%;
  position: fixed;
  bottom: 3.2rem;
}
