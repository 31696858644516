.container {
  display: flex;
  flex-direction: column;
  font-family: 'Pretendard';
}
.confirm-detail-title {
  margin-bottom: 1rem;
  margin-left: 2rem;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  white-space: pre-wrap;
}
.confirm-detail-sub-title {
  margin-bottom: 1rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  white-space: pre-wrap;
}
.confirm-detail-title span {
  color: var(--color-green600);
}
.confirm-detail-text {
  color: var(--color-black200);
  font-size: 1.2rem;
  margin-left: 1.6rem;
  margin-bottom: 5rem;
}

.confirm-detail-member-container {
  margin-bottom: 2.2rem;
}

.confirm-detail-member-items {
  margin-left: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: auto 8rem;
}
.confirm-detail-profile img {
  width: 5.6rem;
  height: 5.6rem;
  margin: 0 auto;
  border-radius: 50%;
}

.confirm-detail-profile p {
  position: relative;
  margin: 0;
  padding: 0;
  top: 1rem;
  font-size: 1.2rem;
  color: var(--color-black500);
  font-weight: bold;
}
.confirm-detail-profile span {
  color: var(--color-green600);
  font-weight: bold;
}

.confirm-detail-line {
  width: 3rem;
  height: 0.5rem;
}

.confirm-detail-meet-container {
  line-height: 2rem;
  margin-bottom: 3rem;
}
.confirm-detail-meet-box {
  display: flex;
  margin: auto 2rem;
  white-space: pre-wrap;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.4rem 1.2rem;
  background-color: var(--color-white50);
  border-radius: 12px;
  margin-bottom: 1rem;
  
  span {
    line-height: 1.8rem;
  }
  img {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.confirm-detail-file-container {
  margin-bottom: 3rem;
}

.confirm-detail-warning-container {
  margin-bottom: 4.8rem;
}
