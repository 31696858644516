.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.button-container {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  position: absolute;
  bottom: 0px;
  margin-top: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.label-container-title {
  margin-bottom: 1rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  white-space: pre-wrap;
}
.label-container {
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.text {
  margin-left: 2.2rem;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: var(--color-black200);
  white-space: pre-wrap;
  margin-bottom: 2.5rem;
}
.text {
  margin-left: 2.2rem;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: var(--color-black200);
  white-space: pre-wrap;
  margin-bottom: 2.5rem;
}
.subText {
  margin-left: 2.2rem;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  margin-bottom: 1.4rem;
  color: var(--color-black200);
  white-space: pre-wrap;
}
.bottom-text {
  position: absolute;
  bottom: 10rem;
  margin-left: 2.2rem;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black200);
  white-space: pre-wrap;
  margin-bottom: 2.5rem;
}

@media screen and (min-width: 1000px) {
  .container {
    margin-bottom: 0;
    height: calc(100vh - 10rem);
  }
  .button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
    bottom: 0;
    margin-top: 0;
  }
}
