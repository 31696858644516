.conatiner {
  display: flex;
  flex-direction: column;
}

.title-text {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.36rem;
  color: var(--color-black800);
  white-space: pre-wrap;
}

.title-sub-text {
  margin-bottom: 5.4rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
  white-space: pre-wrap;
}

.button-container {
  margin-top: auto;
  width: 100vw;
  padding-bottom: 3.2rem;
  padding-left: 2.0rem;
  padding-right: 2.0rem;
  box-sizing: border-box;
  background-color: white;
}