/* Full-screen container for horizontal centering */
.centeredContainer {
  display: flex;
  justify-content: center; /* Centers horizontally */
  margin: auto 1.6rem;
  margin-bottom: 0.4rem;
}

/* Main container for the notice */
.noticeContainer {
  width: 100%;
  border-radius: 0.6rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  background-color: rgba(245, 246, 247, 1);
}

/* Text for the notice */
.text {
  color: rgba(87, 88, 94, 1);
  font-family: 'Pretendard', sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-left: 18px; /* Space between the icon and the text */
}

/* Container for the megaphone icon */
.iconContainer {
  width: 14px;
  height: 14px;
}

/* Icon size styling for the imported SVG */
.icon {
  width: 14px;
  height: 14px;
  fill: currentColor; /* Ensures the icon uses the current text color */
}
