.title-text {
  margin-top: 1.6rem;
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.864rem;
  color: #000000;

  img {
    width: 12.8rem;
    height: 4.2rem;
  }
}

.sub-text {
  margin-top: 0.3rem;
  margin-bottom: 3.2rem;
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: var(--color-black500);
}

.label-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.7rem;
  margin-left: 2.6rem;
  margin-right: 2.4rem;
}

.label {
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #87888d;
}

.label-error {
  margin-left: auto;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.313rem;
  color: #ff3232;
}

.input-field-container {
  display: flex;
  margin-top: 0.7rem;
  margin-bottom: 1.2rem;
  padding-left: 2rem;
  margin-right: 2rem;
}

.account-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.account-label {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.432rem;
  color: var(--color-black500);
}

.account-label-separate {
  margin-left: 1.4rem;
  margin-right: 1.4rem;
  width: 1px;
  height: 1.2rem;
  background-color: rgba(0, 0, 0, 0.07);
}

/* 로딩 효과 */
/* SignIn.module.css */

.loading-spinner {
  border: 0.5rem solid var(--color-green600);
  border-left-color: #eaebec;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1s linear infinite;
  margin: auto;
  margin-top: 20rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
