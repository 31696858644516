.container {
  font-family: 'Pretendard';
  min-height: 100vh;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  color: var(--color-black900);
  margin: auto 2rem;
  margin-top: 2.4rem;
  margin-bottom: 3.2rem;
}

.label {
  margin: auto 2rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black200);
}

.info {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-black500);
  text-decoration: underline;
  margin-top: 2rem;
}

.text-container {
  display: flex;
  margin: auto 2rem;
  gap: 0.8rem;
}

.verification-container {
  margin: auto 2rem;
  gap: 0.8rem;
  margin-bottom: 3.2rem;
  margin-top: 1.2rem;
  .label {
    margin: 0;
  }
}

.button-container {
  width: 100%;
}

.sheet-terms-container {
  font-family: 'Pretendard';
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 1.6rem 2.2rem 1.4rem 1.6rem;
}

.sheet-terms-content {
  font-family: 'Pretendard';
  margin-left: 1.6rem;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
  margin-top: 1.4rem;
  line-height: 1.96rem;
}

.label-error {
  margin-left: auto;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.313rem;
  color: #ff3232;
  margin-right: 1rem;
}
.flex {
  display: flex;
}
