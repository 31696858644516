.inputContainer {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh; /* 전체 화면 높이 설정 */
}

@media (max-width: 600px) {
  .inputContainer {
    padding: 10px; /* 모바일 화면에서 패딩을 줄여줌 */
  }

  .titleInput,
  .contentTextarea {
    width: 100vw; /* 모바일 화면에서 가로폭을 전체 화면으로 설정 */
    margin-left: -10px; /* 부모 패딩과 균형 맞추기 */
    margin-right: -10px; /* 부모 패딩과 균형 맞추기 */
  }
}

.titleInput {
  width: 100%;
  padding: 10px;
  font-family: Pretendard;
  border: none;
  font-size: 24px;
  outline: none;
  font-weight: bold;
  box-sizing: border-box;
}


.contentTextarea {
  width: 100%;
  padding: 10px;
  border: none;
  font-size: 16px;
  outline: none;
  resize: none;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 19.5px */
  letter-spacing: -0.13px;
  flex-grow: 1; /* 남은 공간을 모두 차지하도록 설정 */
  height: 100%; /* 전체 높이를 차지 */
}

.contentTextarea:focus {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3); /* 포커스 시 살짝 진해짐 */
}

input::placeholder,
textarea::placeholder {
  color: #999;
  font-style: normal; /* 이탤릭체 제거 */
}
