.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.label-container {
  margin-top: 2.4rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  white-space: pre-wrap;
}
.label {
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #87888d;
}
.space {
  margin: 1rem;
}
.input-field-container {
  margin-top: 0.7rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
  background-color: var(--color-white50);
  input {
    background-color: var(--color-white50);
    color: var(--color-black500);
  }
  border-radius: 12px;
}

.password-reset-button {
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  color: #57595E;
  text-decoration: underline;
}