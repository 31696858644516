.container {
  display: flex;
  flex-direction: column;
  font-family: 'Pretendard';
}

.label-container {
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  margin-top: 0.6rem;
}

.review-container {
  margin-left: 2rem;
  margin-right: 2rem;
}
.review-item {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-grey100);
  border-radius: 14px;
  padding: 1.4rem 1.4rem 0 1.4rem;
  margin-top: 1rem;
  margin-bottom: 1.2rem;
  .profile-image {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.4rem;
  }
}
.review-rating {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.review-rating img {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.2rem;
}

.review-date {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.96rem;
  color: var(--color-black500);
}

.container-flex {
  display: flex;
  align-items: center;

  img {
    border-radius: 50px;
    object-fit: cover;
  }
}
.rating-flex {
  display: flex;
}

.nickname {
  font-size: 1.4rem;
  font-weight: 600;
  margin-left: 0.4rem;
}

.review-flex {
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
}
