/* Ensure the entire page takes up the full height */
.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  padding: 0;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: hidden; /* Avoid accidental vertical scroll issues */
}

/* Scrollable content area */
.contentContainer {
  flex: 1; /* Allows content to grow and fill the available space */
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 20px; /* Padding to make the content not touch edges */
  box-sizing: border-box; /* Ensure padding is included in width calculations */
  max-width: 100vw; /* Ensure content does not exceed viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Responsive Design for Mobile (max-width: 768px) */
@media (max-width: 768px) {
  /* Make content fit the screen properly on mobile devices */
  .contentContainer {
    padding: 10px; /* Reduce padding to save space on mobile */
    max-width: 100vw; /* Ensure content stays within the screen */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  /* Adjust the footer styling for mobile */
  .footer {
    padding: 10px 0; /* Smaller padding for mobile screens */
    font-size: 14px; /* Adjust font size for footer text */
    max-width: 100vw; /* Prevent horizontal scrolling */
  }
}

/* Global styles to ensure no horizontal scrolling */
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll on the entire page */
  width: 100vw; /* Ensure body takes full viewport width */
  height: 100%; /* Ensure the body takes the full height */
  box-sizing: border-box;
}
.sheet-terms-container {
  font-family: 'Pretendard';

  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 1.6rem 2.2rem 1.4rem 1.6rem;
}

.sheet-terms-content {
  font-family: 'Pretendard';
  margin-left: 1.6rem;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
  margin-top: 1.4rem;
  line-height: 1.96rem;
}
