.container {
  font-family: 'Pretendard';
}

.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin: auto 2rem;
  margin-bottom: 1rem;
  margin-top: 2.4rem;
}

.sub-title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  white-space: pre-wrap;
  margin: auto 2rem;
}

.completed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 16rem;
    height: 16rem;
    object-fit: contain;
    margin-top: 3.2rem;
    margin-bottom: 2.4rem;
  }
  .sub-title {
    color: var(--color-black200);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.96rem;
    white-space: pre-wrap;
    margin-bottom: 1.2rem;
  }
}
.button {
  width: 100%;
  margin-bottom: 2rem;
}
.link {
  color: var(--color-black500);
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  text-decoration: underline;
}
