.container {
  font-family: 'Pretendard';
}
.item {
  margin-right: 1.6rem;
  text-align: right;
  font-size: 1.3rem;
  color: var(--color-black200);
  margin-top: 2rem;
  cursor: pointer;
  font-weight: bold;
}

.alarm-container {
  margin-top: 0.8rem;
  background-color: var(--color-green50);
}

.sheet-terms-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pretendard';
  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 1.6rem 2.2rem 0 1.6rem;
}

.sheet-terms-content {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  font-family: 'Pretendard';
}

.button-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
  /* filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16)); */
}

.failed-container {
  margin: auto 1.6rem;
  background-color: #f7f8f9;
  padding: 1.6rem 2.4rem;
  margin-bottom: 1.6rem;
}

.failed-text {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-black500);
  line-height: 1.92rem;
  margin-bottom: 0.4rem;
}

.failed-flex {
  display: flex;
  justify-content: space-between;
  margin: 1.6rem auto;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black900);
}

.failed-title {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.96rem;
  margin: auto 1.6rem;
  margin-bottom: 1.2rem;
}
