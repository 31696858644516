.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.container-title {
  margin-top: 2.4rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  white-space: pre-wrap;
}

.form-container {
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
}

.label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0.7rem;
  margin-left: 2.6rem;
  margin-right: 2.4rem;
}

.label {
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #888b93;
}

.label-error {
  margin-left: auto;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.313rem;
  color: #ff3232;
}

.label-description {
  margin-left: 2.6rem;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: #aaacb6;
}

.label-prefix {
  margin-left: auto;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.313rem;
  color: #00b76a;
}

.language-container {
  margin-left: 2rem;
  margin-top: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.6rem;
  row-gap: 1.6rem;
}

.language-item {
  padding-left: 1.2rem;
  padding-right: 1.4rem;
  height: 3.7rem;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: #1d1d1e;

  img {
    margin-right: 0.6rem;
  }
}

.language-item-others {
  text-align: center;
  margin-top: 2.4rem;
  padding-bottom: 1.2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  text-decoration: underline;
  color: #00b76a;
}

.notification-container {
  margin-left: 2rem;
  margin-top: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.2rem;
}

.notification-item {
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  height: 3.7rem;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 3.7rem;
  color: #1d1d1e;
}

.notification-item-active {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #00b76a;
  color: #ffffff;
}

.input-field-container {
  margin-top: 0.7rem;
  margin-bottom: 1.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
}

.profile-image-container {
  height: 8rem;
  position: relative;
}

.profile-image-thumbnail {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  img {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
  }
}

.profile-image-camera-icon {
  position: absolute;
  right: -14px;
  top: 0;

  img {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
  }
}

.button-container {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.sheet-terms-container {
  font-family: 'Pretendard';

  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 1.6rem 2.2rem 1.4rem 1.6rem;
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #1d1d1e;
}
.select-container {
  margin: auto 2rem;
}
