.container {
  font-family: 'Pretendard';
  position: relative;
}

.title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.52rem;
  margin: auto 1.6rem;
  margin-top: 2.4rem;
}

.text {
  font-size: 1.5rem;
  font-weight: 600;
  margin: auto 1.6rem;
  color: var(--color-black500);
  line-height: 2.1rem;
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  white-space: pre-wrap;
}

.info-box {
  margin: auto 1.6rem;
  padding: 2.4rem 1.8rem;
  background-color: var(--color-white50);
  border-radius: 12px;
  img {
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: 1.6rem;
  }
}
.info-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  margin-bottom: 1.6rem;
}
.info-text {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  color: var(--color-black500);
  white-space: pre-wrap;
}

.button-container {
  width: 100%;
  position: fixed;
  bottom: 3.2rem;
}
@media screen and (min-width: 1000px) {
  .container {
    height: calc(100vh - 5.6rem);
  }
  .button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
  }
}
