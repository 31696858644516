.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1.6rem;
  height: 4.8rem;
  border-radius: 1.2rem;
  border: solid 1px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--color-black800);
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 0rem;
  }
}

.combobox-icon {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.button-container {
  margin-left: auto;
  width: 7.2rem;
  height: 3.6rem;
  text-align: center;
  background-color: #f4f7fb;
  border-radius: 8px;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 3.6rem;
  color: #416bff;
}
