.container {
  font-family: 'Pretendard';
}

.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 2rem;
  span {
    color: var(--color-green600);
  }
}

.card-item {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.96rem;
}

.date {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.54rem;
  color: var(--color-white800);
}
.waiting {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.68rem;
}
.fail {
  color: var(--color-red400);
}
