.suggested-container {
  display: flex;
  flex-direction: column;
  font-family: 'Pretendard';
  padding-bottom: 8rem;
}
.suggested-title {
  margin-bottom: 1rem;
  margin-left: 2rem;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  white-space: pre-wrap;
}
.suggested-sub-title {
  margin-bottom: 1rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  white-space: pre-wrap;
}
.suggested-coupon-container {
  margin-bottom: 3rem;
}
.suggested-select-container {
  margin-bottom: 3rem;
}
.suggested-text-container {
  margin-bottom: 3rem;
}

.suggested-text-item {
  white-space: pre-wrap;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.4rem 1.2rem;
  background-color: var(--color-white50);
  border-radius: 12px;
}

.suggested-info-container {
  background-color: var(--color-white50);
  margin: auto 1.6rem;
  padding: 1.2rem 1.4rem;
  border-radius: 1.2rem;
  margin-bottom: 3rem;
}
.suggested-info-container img {
  width: 7.2rem;
  height: 7.2rem;
  margin-bottom: 1.6rem;
  border-radius: 50%;
}

.suggested-info-line {
  width: 100%;
  height: 0.1rem;
  background-color: var(--color-black_transparent_6);
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

.suggested-info-text {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.suggested-info-text span {
  text-decoration: underline;
}
.suggested-info-sub-text {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-black500);
}
.suggested-info-link {
  font-weight: bold;
  font-size: 1.4rem;
  color: var(--color-green600);
  margin-top: 1.6rem;
}
.suggested-sub-text {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--color-black200);
  white-space: pre-wrap;
}

.suggested-file-container {
  padding-bottom: 12rem;
}

.button-container {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  position: fixed;
  bottom: 0px;
  justify-content: center;
  gap: 0.5rem;
}
.button-loading {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  position: fixed;
  bottom: 0px;
  justify-content: center;
  gap: 0.5rem;
  pointer-events: none;
}
@media screen and (min-width: 1000px) {
  .suggested-container {
    height: 100vh;
    margin-bottom: 8rem;
  }
  .suggested-info-container {
    margin-top: 0;
  }
  .button-container {
    position: sticky;
    bottom: 0;
    min-width: 320px;
    width: 100%;
  }
  .button-loading {
    position: sticky;
    bottom: 0;
    min-width: 320px;
    width: 100%;
    pointer-events: none;
  }
}
