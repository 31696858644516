.container {
  font-family: 'Pretendard';
  position: relative;
  padding-bottom: 8rem;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin: auto 2rem;
  margin-top: 2.4rem;
}

.text {
  font-size: 1.5rem;
  font-weight: 600;
  margin: auto 2rem;
  color: var(--color-black500);
  line-height: 2.1rem;
  margin-top: 1rem;
  margin-bottom: 3.7rem;
  white-space: pre-wrap;
}

.text-item {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.96rem;
  color: var(--color-black200);
  margin: auto 1.6rem;
  margin-bottom: 2.4rem;
}

.text-label {
  margin-left: 0.6rem;
  margin-bottom: 0.7rem;
}

.button-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
}
