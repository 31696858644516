.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.profile-container {
  text-align: center;
  position: relative;
  img {
    margin: 0;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    border: 2px solid var(--color-black_transparent_6);
  }
}

.profile-btn {
  width: 3.4rem;
  height: 3.4rem;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  left: 6.5rem;
  margin: 0 auto;
  img {
    border: 2px solid white;
    background-color: var(--color-white100);
    width: 3.4rem;
    height: 3.4rem;
  }
}

.profile-nickname {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2.52rem;
  color: var(--color-black900);
}

.label {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black200);
  margin-bottom: 0.7rem;
  margin-top: 1.4rem;
}

.label-info {
  font-size: 1.1rem;
}

.text-container {
  margin: auto 2rem;
  margin-top: 2rem;
}

.mate-tag {
  margin-top: 1.2rem;
  margin-bottom: 0.4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: 5px 14px 5px 14px;
  background-color: #deffdc;
  border-radius: 24px;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.08rem;
  color: var(--color-green800);
}

.profile-image-container {
  margin-top: 0.8rem;
  margin-bottom: 2rem;
  height: 8rem;
  position: relative;
}

.profile-image-thumbnail {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  img {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
  }
}

.profile-nickname {
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black900);
}

.profile-introduce-message {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.8rem;
  width: 33.5rem;
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
  white-space: pre-wrap;
}

.divider {
  margin-bottom: 0.8rem;
  height: 8px;
  background-color: var(--color-white50);
}

.label-container {
  margin-left: 2rem;
  margin-right: 2rem;
  height: 5.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.label-title {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-white700);
}

.label-content {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black900);
}

.image-upload {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.4rem;
  height: 3.4rem;
  border: 2px solid var(--color-white1000);
  border-radius: 50%;
  img {
    width: 3.4rem;
    height: 3.4rem;
    margin: 0;
  }
}
.image-header {
  position: absolute;
  width: 100%;
}
.image-container {
  position: relative;
  height: 100vh;
  background-color: var(--color-black1000);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload-text {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.96rem;
  color: var(--color-black900);
  margin: auto 1.6rem;
  margin-bottom: 1.2rem;
}

.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #1d1d1e;
}

@media screen and (min-width: 1000px) {
  .image-container {
    height: calc(100vh - 4rem);
  }
  .image-header {
    position: relative;
  }
}
