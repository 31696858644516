.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1.6rem;
  padding-right: 0.6rem; /*1.4rem*/
  height: 4.8rem;
  border-radius: 12px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  margin: auto 2rem;
}

.input-label {
  margin: 1.4rem 2rem;
  margin-bottom: 0.7rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-black200);
  line-height: 1.68rem;
}

.container-focused {
  border-color: #a6b9fb;
}

.container-error {
  border-color: #fba6a6;
}

.input-field::-webkit-input-placeholder {
  color: var(--color-black200);
}

.input-field::-moz-placeholder {
  color: var(--color-black200);
}

.input-field:-ms-input-placeholder {
  color: var(--color-black200);
}

.input-field::placeholder {
  color: var(--color-black200);
}

.input-field {
  width: 100%;
  border: none;
  outline: none;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--color-black800);
}

.select-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: auto 2rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black900);
  img {
    margin: 0 0.6rem;
    width: 2rem;
    height: 2rem;
  }
}

.select-item {
  display: flex;
  justify-content: space-between;
}

.action-button {
  flex-shrink: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 7.2rem;
  height: 3.6rem;
  border-radius: 8px;
  text-align: center;
  background-color: #d8d9dc;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 3.6rem;
  color: white;
}

.action-button-enable {
  background-color: #f4f7fb;
  color: #416bff;
}
