@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
  
.openAnimation {
  animation: fadeIn 0.3s ease-in-out 0s 1 normal forwards;
}
  
.closeAnimation {
  animation: fadeOut 0.3s ease-in-out 0s 1 normal forwards;
}

.toast {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2.4rem;
    background-color: #28282B;
    z-index: 99999;
    width: 33.5rem;
    height: 4.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1.6rem;    
    padding-right: 1.6rem;
    border-radius: 1.2rem;
    box-sizing: border-box;
    font-family: 'Pretendard';
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.96rem;
    color: #FFFFFF;
    white-space: pre-wrap;

    img {
        margin-right: 8px;
    }
}