/* Main tabs container */
.tabs {
  display: flex;
  padding: 0.8rem 1.6rem;
  justify-content: flex-start;
  background-color: #ffffff;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e9ecef; /* Added border for visual separation */
  overflow-x: auto; /* Allows for horizontal scrolling */
  white-space: nowrap; /* Prevents tabs from wrapping */
  box-sizing: border-box;
}
.tabs::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}
/* Horizontal frame that contains the chips */
.frame {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  flex-wrap: nowrap; /* Ensures tabs stay on one line */
}

/* Individual tab (chip) styling */
.chip {
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* transition: background-color 0.1s ease, color 0.1s ease; */
  font-family: 'Pretendard', sans-serif; /* Matching font style */
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.671rem;
  border-radius: 0.6rem; /* Rounded corners for all chips */
  color: var(--color-white700);
}

/* Active chip (HOT tab) styling */
.activeChip {
  background-color: #1d1d1e; /* Dark background for active tab */
  color: #ffffff; /* White text for active tab */
}

/* Text inside the tab when it's inactive */
.inactiveChip {
  color: #999dab; /* Gray color for inactive tab text */
  background-color: transparent; /* No background for inactive tabs */
}
