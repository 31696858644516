.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.master {
  display: block;
}
.logoBox {
  display: none;
}


@media screen and (min-width: 1000px) {
  .master {
    font-family: 'Pretendard';
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;
    margin: 0 5%;
  }

  .logoBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 36.6rem;
      max-width: 36.6rem;
      height: auto;
    }
  }

  .naileditBox {
    width: 100%;
    min-width: 320px;
    max-width: 600px;
    background-color: #ffffff;
    overflow-y: auto;
    margin: 0 auto;
  }
}
