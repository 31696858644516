.conatiner {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.image {
  margin-left: 2.0rem;
  margin-bottom: 1.6rem;
  
  img {
    width: 6.8rem;
    height: 5.4rem;
  }
}

.title-text {
  margin-bottom: 1.0rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.36rem;
  color: var(--color-black800);
}

.title-sub-text {
  margin-left: 2.0rem;
  margin-right: 2.0rem;
  margin-bottom: auto;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: #848691;
  white-space: pre-wrap;
}

.bottom-text {
  margin-left: 2.0rem;
  margin-bottom: 0.8rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #87888D;
}

.bottom-text-button {
  margin-left: 2.0rem;
  margin-bottom: 4.0rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: black;
  text-decoration: underline;
}

.button-container {
  width: 100vw;
  padding-bottom: 3.2rem;
  padding-left: 2.0rem;
  padding-right: 2.0rem;
  box-sizing: border-box;
  background-color: white;
}
@media screen and (min-width: 1000px) {
  .button-container{
    min-width: 320px;
    width: 600px;

  }
 

}