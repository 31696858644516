.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  position: fixed; /*sticky;*/
  bottom: 0px;
  height: 5.6rem;
  overflow: hidden;
  border-top: 1px solid #f3f6f9;
}

.nav-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.nav-item {
  width: 100%;
  height: 3.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #57595e;
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  color: var(--color-black500);

  img {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0;
  }
}

.active-nav-item {
  color: var(--color-nailet_green600);
}

@media screen and (min-width: 1000px) {
  /* pc버전일때 영역을 벗어나서 sticky설정 */
  .footer {
    position: sticky;
  }
}
