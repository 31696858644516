.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.label-container {
  margin-top: 2.4rem;
  margin-bottom: 3rem;
  margin-left: 2rem;
  font-family: "Pretendard";
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  /* white-space: pre-wrap; */
}
.text {
  margin-left: 2.2rem;
  vertical-align: middle;
  font-family: "Pretendard";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black500);
  white-space: pre-wrap;
}
.resetEmail-icon {
  text-align: center;
}
.resetEmail-icon img {
  width: 12rem;
  height: 12rem;
  margin: 2.5rem;
}
