.bannerBox {
  font-family: 'Pretendard';
  margin-bottom: 1.2rem;
  background-color: var(--color-green600);
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  border-radius: 12px;
  height: 5.6rem;
  line-height: 5.6rem;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  text-align: left;
  overflow: hidden;
}

.banner1 {
  background: url(../assets/images/home/banner1.png) no-repeat center center;
  background-size: 100%;
  text-align: left;
  text-indent: 1rem;
  font-size: 1.6rem;
}
.banner2 {
  background: url(../assets/images/home/banner2.png) no-repeat center center;
  background-size: 100%;

  text-align: center;
  font-size: 1.5rem;
}

.alarm-count {
  font-family: 'Pretendard';
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 0.3rem;
  right: 1.5rem;
  background-color: var(--color-red400);
  color: white;
  font-size: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  line-height: 1.8;
}

.search-bar {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  margin-bottom: 2rem;
  padding-left: 1.2rem;
  border-radius: 12px;
  height: 4.2rem;
  background-color: #f7f8f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #989da1;

  img {
    margin-right: 0.6rem;
    width: 2rem;
    height: 2rem;
  }
}

.category-bar {
  width: 100vw;
  height: 3.6rem;
  margin-bottom: 1.6rem;
  padding-left: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  overflow-x: auto;
  white-space: nowrap;
  box-sizing: border-box;
  scrollbar-width: none;

  img {
    margin-right: 2rem;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.category-item {
  position: relative;
  height: 3.6rem;
  margin-right: 2.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #999dab;
}

.category-item-active {
  color: #1d1d1e;
}

.category-active {
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-bottom: 3px solid #1d1d1e;
}

.profile-list-container {
  padding-left: 2rem;
  padding-right: 1.6rem;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 17.3rem;
  overflow-x: auto;
  white-space: nowrap;
  box-sizing: border-box;
  scrollbar-width: none;
}

.profile-list-item {
  flex-shrink: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  background-color: #f7f8f9;
  margin-right: 0.4rem;
  width: 12.4rem;
  height: 17.3rem;
  border-radius: 16px;
}

.profile-list-item-active {
  border: 2px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.profile-item-image-group {
  margin-left: 1.2rem;
  margin-right: 1.4rem;
  margin-top: 1rem;
  margin-bottom: 1.1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-item-thumbnail {
  margin-top: 0.4rem;
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.02);
  box-sizing: border-box;

  .profile-item-thumbnail-img {
    width: 5.2rem;
    height: 5.2rem;
    border-radius: 50%;
  }
}

.profile-item-bookmark {
  img {
    margin-right: 0rem;
    width: 2rem;
    height: 2rem;
  }
}

.profile-item-tag {
  margin-left: 1rem;
  margin-right: auto;
  margin-bottom: 0.2rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  height: 1.9rem;
  background-color: rgba(0, 0, 0, 0.64);
  border-radius: 4px;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.9rem;
  color: white;
  max-width: 10.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-item-nickname {
  margin-top: 0.4rem;
  margin-left: 1rem;
  margin-bottom: 0.1rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.82rem;
  color: #1d1d1e;
}

.profile-item-company {
  margin-left: 1rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.56rem;
  color: #1d1d1e;
}

.profile-list-see-more-container {
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0.8rem;
  margin-right: 1.6rem;
  width: 9.6rem;
  height: 17.3rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.56rem;
  color: #131314;
}

.profile-list-see-more-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.2rem;
  height: 5.2rem;
  background-color: #edebf0;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.02);
  margin-bottom: 8px;

  img {
    margin-right: 0;
    width: 2.8rem;
    height: 2.8rem;
  }
}

.meeting-category-title-container {
  margin-top: 3.6rem;
  margin-bottom: 1.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.meeting-category-title-text {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.909rem;
  color: #1d1d1e;
}

.meeting-category-all-view {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #999dab;
}

.meeting-category-item-container {
  margin-left: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 7px;
  row-gap: 8px;
}

.meeting-category-item {
  width: 16.8rem;
  height: 4.8rem;
  background-color: var(--color-white50);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #1d1d1e;
}

.meeting-category-item-shape {
  width: 0.5rem;
  height: 4.8rem;
  border-radius: 6px 0px 0px 6px;
  background-color: #00db7f;
  margin-right: auto;
}

.meeting-category-item-text {
  width: 100%;
  text-align: left;
  padding-left: 1.1rem;
}

.meeting-country-title-container {
  margin-top: 3.6rem;
  margin-bottom: 1.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.meeting-country-title-text {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.909rem;
  color: #1d1d1e;
}

.meeting-country-all-view {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #999dab;
}

.meeting-country-item-container {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: auto;
  overflow-x: auto;
  white-space: nowrap;
  box-sizing: border-box;
  img {
    border-radius: 50%;
  }
  padding-bottom: 1rem;
}

.meeting-country-item {
  margin-right: 0.4rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f8f9;
  width: 7.9rem;
  height: 6.8rem;
  border-radius: 12px;
}

.meeting-country-item-icon {
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;

  img {
    width: 3.2rem;
    height: 3.2rem;
  }
}

.meeting-country-item-text {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.44rem;
  color: var(--color-black800);
}

.review-title {
  text-align: left;
  margin-left: 2rem;
  margin-top: 3rem;
  margin-bottom: 1.2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.909rem;
  color: var(--color-black800);
}

.review-container {
  position: relative;

  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
  width: 100vw;
  height: 18.9rem;
  overflow-x: auto;
  white-space: nowrap;
  box-sizing: border-box;
  margin-bottom: 4rem;
  img {
    border-radius: 50%;
  }
}

.review-item {
  margin-right: 0.8rem;
  padding: 1.6rem;
  width: 29.2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 14px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  flex-shrink: 0; /* flex가 크기를 축소하지 않도록 설정 */
}

.review-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.6rem;

  img {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.4rem;
    border-radius: 50%;
  }
}

.review-item-nickname {
  margin-right: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: var(--color-black800);
}

.review-item-date {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #bec1c6;
}

.review-item-score {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.2rem;
  }
}
.review-item-company {
  font-family: 'Pretendard';
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-black500);
}

.mate-item {
  position: absolute;
  bottom: 1.6rem;
  width: 26.4rem;
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: 'Pretendard';
  background-color: var(--color-white50);
  border-radius: 0.6rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.mate-info {
  font-style: 1.2rem;
  font-weight: 600;
  color: var(--color-black900);

  span {
    color: var(--color-blue600);
    font-style: 1.2rem;
    font-weight: 600;
    margin-right: 5rem;
    margin-left: 0.5rem;
  }
}
.mate-profile {
  margin-left: 0.7rem;
  img {
    border-radius: 50%;
    margin: 0;
    margin-right: 0.4rem;
  }
}
.mate-link {
  position: absolute;
  right: 0.7rem;
  color: var(--color-white700);
  font-weight: 600;
}

.information-container {
  font-family: 'Pretendard';
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  padding-bottom: 7.2rem;
  border-bottom: 2px solid var(--color-white200);
}
.information-section {
  margin: auto 2rem;
}

.information-image-container {
  text-align: center;
  background-color: var(--color-green150);
  height: 154px;
  img {
    margin-top: 1.6rem;
    width: 13.1rem;
    height: 138px;
  }
}
.information-title {
  font-size: 2.3rem;
  font-weight: bold;
  line-height: 3.12rem;
  margin-top: 2.4rem;
  /* margin-bottom: 2.4rem; */
}
.information-text {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;
}
.information-image2 {
  text-align: center;
  img {
    width: 18.8rem;
    height: 125px;
  }
}
.information-image3 {
  text-align: center;
  img {
    width: 26.5rem;
    height: 161px;
  }
}
.information-image4 {
  text-align: center;
  img {
    width: 26.8rem;
    height: 142px;
  }
}
.button-container {
  margin-top: 4rem;
}

.footer-information-container {
  margin-top: 2.4rem;
  margin-bottom: 9rem; /*3.4rem + 5.6rem*/
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  color: var(--color-black600);
}

.footer-information-title {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  margin-bottom: 8px;
}

.footer-information-menu {
  cursor: pointer;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.24rem;
  display: flex;
  margin-bottom: 3px;

  img {
    width: 2px;
    height: 20px;
    margin-right: 0;
  }
}

.footer-information-site-icons-row-1 {
  margin-top: 19px;
  display: flex;
  flex-direction: row;
  column-gap: 15px;

  img {
    width: 35px;
    height: 35px;
    margin-right: 0;
  }
}

.footer-information-site-icons-row-2 {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  column-gap: 15px;

  img {
    width: 35px;
    height: 35px;
    margin-right: 0;
  }
}

.footer-information-address {
  margin-top: 25px;
  text-align: left;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.68rem;
}

.footer-information-copyright {
  margin-top: 9px;
  text-align: left;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
}

@media screen and (min-width: 1000px) {
  .category-bar,
  .profile-list-container,
  .meeting-country-item-container,
  .review-container {
    width: 100%;
  }
}

/* 링크 밑줄 제거 */
a {
  text-decoration: none;
  color: inherit;
}

.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.terms-update {
  /* border: 1px solid; */
  padding: 0.2rem;
}

.sheet-terms-content {
  word-break: break-all;
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}
.sheet-terms-sub-title {
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  height: 4.14rem;
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black800);
}

.sheet-terms-button-container {
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  box-sizing: border-box;
}

.sheet-terms-button {
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 12px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background: var(--color-green600);
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  color: white;
}
.terms-container {
  margin-top: 2rem;
}
.terms-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 2.6rem;
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.432rem;
  color: var(--color-black800);

  img {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.6rem;
  }
}
.terms-view-content {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: auto;
  margin-right: 2rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.432rem;
  text-decoration: underline;
  color: #9a9ca2;
}

.sheet-button-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}
