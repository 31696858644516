.container {
  font-family: 'Pretendard';
  position: relative;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin: auto 2rem;
  margin-top: 2.4rem;
}

.text {
  font-size: 1.5rem;
  font-weight: 600;
  margin: auto 2rem;
  color: var(--color-black500);
  line-height: 2.1rem;
  margin-top: 1rem;
  margin-bottom: 3.7rem;
  white-space: pre-wrap;
}

.text-item {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.96rem;
  color: var(--color-black200);
  margin: auto 1.6rem;
  margin-bottom: 2.4rem;
}

.text-label {
  margin-left: 0.6rem;
  margin-bottom: 0.7rem;
}

.button-container {
  width: 100%;
  position: fixed;
  bottom: 3.2rem;
}
@media screen and (min-width: 1000px) {
  .container {
    height: calc(100vh - 5.6rem);
  }
  .button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
  }
}
