.container {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1.6rem;    
    padding-right: 0.6rem; /*1.4rem*/
    height: 4.8rem;
    border-radius: 12px;
    border: solid 1px rgba(0, 0, 0, .12);
    box-sizing: border-box;   
img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
}

.container-focused {
    border-color: #A6B9FB;
}

.container-error {
    border-color: #FBA6A6;
}

.input-field::-webkit-input-placeholder {
  color: #9A9CA2;
}
  
.input-field::-moz-placeholder {
  color: #9A9CA2;
}
  
.input-field:-ms-input-placeholder {
  color: #9A9CA2;
}

.input-field::placeholder {
  color: #9A9CA2;
}


.input-field {
  width: 100%;
  border: none;
  outline: none;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--color-black800);
}

.input-field-placeholder-black::-webkit-input-placeholder {
  color: #000000;
}
  
.input-field-placeholder-black::-moz-placeholder {
  color: #000000;
}
  
.input-field-placeholder-black:-ms-input-placeholder {
  color: #000000;
}

.input-field-placeholder-black::placeholder {
  color: #000000;
}

.action-button {
  flex-shrink: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 7.2rem;
  height: 3.6rem;
  border-radius: 8px;
  text-align: center;
  background-color: #D8D9DC;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 3.6rem;
  color: white;
}

.action-button-enable {
  background-color: #F4F7FB;
  color: #416BFF;
}