.card-container {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  margin-bottom: 4rem;
  padding-top: 2rem;
  padding-bottom: 1.2rem;
  /*width: 34.3rem;*/
  /*height: 16.1rem;*/
  border-radius: 16px;
  border: 2px solid #e9ecef;
}

.card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-suggest-type-text {
  margin-left: 1.6rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: black;
}

.card-price {
  margin-left: auto;
  margin-right: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #9ea0a4;
  text-decoration: line-through;
}

.card-coupon-price-text {
  margin-right: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #00b76a;
}

.card-final-price {
  margin-right: 1.6rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #ff3232;
}

.card-thumbnail {
  margin-left: 1.6rem;
  margin-right: 0.4rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  object-fit: cover;
}

.card-mate-nickname {
  margin-right: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #00b76a;
}

.card-suggest-nickname {
  margin-right: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  text-decoration: underline;
  color: var(--color-black500);
}

.card-suggest-text {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
}
