.container {
  font-family: 'Pretendard';
  position: relative;
  height: 100vh;
}
.link {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 500;
}

.title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.52rem;
  margin: auto 2rem;
}

.option-container {
  display: flex;
  flex-direction: column;
  margin: 1.6rem 2rem;
  /* padding: 1.6rem; */
  border: 1px solid var(--color-black_transparent_12);
  border-radius: 20px;
}
.option-item {
  display: flex;
  justify-content: space-between;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.52rem;
  padding: 1.6rem;
  padding-bottom: 0.6rem;
  strong {
    color: var(--color-green600);
  }
}

.option-text {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  color: var(--color-black500);
  padding: 1.6rem;
  padding-top: 0;
}

.option-state {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.82rem;
  color: var(--color-green700);
  background-color: var(--color-green70);
  padding: 0.6rem 1rem;
  border-radius: 6px;
}

.option-btn {
  position: relative;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid var(--color-black_transparent_12);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  color: var(--color-black500);
  text-align: center;
}
.line {
  position: absolute;
  top: 0;
  width: 0.1rem;
  height: 4.5rem;
  background-color: var(--color-black_transparent_12);
}

.option-description {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.68rem;
  color: var(--color-white800);
  white-space: pre-wrap;
  margin: auto 2rem;
}

.option-add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  margin: auto 2rem;
  padding: 1.4rem 1.6rem;
  border: 1px solid var(--color-black_transparent_12);
  border-radius: 12px;
  margin-top: 2.8rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  img {
    width: 2rem;
    height: 2rem;
    margin: 0;
  }
}

.sheet-terms-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pretendard';

  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 2.2rem 1.6rem 1.4rem 1.6rem;
}

.sheet-terms-content {
  font-family: 'Pretendard';
}
.text {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  margin: auto 1.6rem;
  margin-bottom: 3.6rem;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin: auto 1.6rem;
  gap: 0.5rem;
}

.btn {
  flex: 1;
  padding: 1.2rem 1.6rem;
  cursor: pointer;
}
