.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.title-text {
  margin-top: 2.4rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  white-space: pre-wrap;
}

.description-text {
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
}

.label-container {
  margin-left: 2.6rem;
  margin-bottom: 0.7rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: var(--color-black200);
}

.input-field-container {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.2rem;
  gap: 8px;
}

.resend-text {
  text-align: center;
  margin-top: 2rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  text-decoration: underline;
  color: var(--color-black500);
}

.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black800);

  p {
    margin: 0px;
    padding: 0px;
  }
}
@media screen and (min-width: 1000px) {
  .container {
    min-width: 320px;
    width: 100%;
  }
}
