.postContent {
  margin: auto 1.6rem;
  border-bottom: 0.1rem solid var(--color-white200);
  padding-bottom: 1.1rem;
  margin-bottom: 2rem;
}

.postTitle {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.96rem;
}

.postHeader {
  margin-bottom: 0.7rem;
}

.postInfo {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.postDetails {
  display: flex;
  color: var(--color-white800);
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.44rem;
}

.postReplyChip {
  display: flex;
  align-items: center;
  background-color: var(--color-white50);
  padding: 0.6rem;
  border-radius: 0.6rem;

  img {
    width: 1.2rem;
    height: 12px;
    margin: 0;
    margin-right: 0.3rem;
  }
}
