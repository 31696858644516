.coupon-container {
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  padding: 1.4rem 1.2rem;
  border-radius: 16px;
  border: 2px solid var(--color-white200);
  margin-bottom: 1.2rem;
  font-family: 'Pretendard';
}

.coupon-container img {
  margin-right: 0.2rem;
}

.coupon-top-item {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-black500);
  margin-top: 0.2rem;
  span {
    margin-right: 0.6rem;
  }
  img {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.coupon-text {
  font-size: 1.8rem;
  color: var(--color-black1000);
  font-weight: 600;
}

.coupon-bottom {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 0.8rem;
}

.coupon-label {
  color: var(--color-green600);
  font-weight: bold;
  font-size: 1.6rem;
}

.coupon-price {
  color: var(--color-black900);
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: 0.6rem;
}

.coupon-sub-price {
  color: var(--color-black500);
  font-size: 1.4rem;
  margin-right: 0.8rem;
}

.coupon-couponPrice {
  color: var(--color-red400);
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: 0.6rem;
}

.coupon-container:active {
  border-color: var(--color-green300); /* active 상태에서의 테두리 색상 */
}
