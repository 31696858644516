.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.search-result-container {
  margin-top: 1.2rem;
  flex: 1;
  box-sizing: border-box;
  overflow-y: auto;
}

.search-result-item {
  margin-left: 2.4rem;
  margin-right: 2rem;
  margin-bottom: 0.3rem;
  height: 4.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: var(--color-black800);

  img {
    margin-right: 0rem;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.search-result-item-selected {
  color: #00b76a;
}
@media (min-width: 1000px) {
  .container {
    min-width: 32rem;
    width: 100%;
  }
}
