.title,
.text {
  margin: auto 2rem;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin-top: 2.4rem;
  margin-bottom: 1rem;
}

.text {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-black200);
  line-height: 2.1rem;
}

.button-container {
  position: fixed;
  bottom: 3.2rem;
  width: 100%;
}
@media screen and (min-width: 1000px) {
  .button-container {
    min-width: 320px;
    width: 600px;
  }
}
