.container {
  font-family: 'Pretendard';
  position: relative;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin: auto 2rem;
  margin-top: 2.4rem;
}

.text {
  font-size: 1.5rem;
  font-weight: 600;
  margin: auto 2rem;
  color: var(--color-black500);
  line-height: 2.1rem;
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  white-space: pre-wrap;
}

.type-container {
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 600;
  text-align: center;
  margin: auto 2rem;
}

.type-item {
  display: flex;
  justify-content: center;
  padding: 1.4rem;
  border: 1px solid var(--color-black_transparent_12);
  margin: 0.7rem auto;
  border-radius: 12px;
  img {
    width: 2rem;
    height: 2rem;
    margin: 0;
    margin-left: 0.2rem;
  }
}

.button-container {
  width: 100%;
  position: fixed;
  bottom: 3.2rem;
}
