.container {
  width: 100vw;
  display: flex;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  border-radius: 12px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
}

.container-focused {
  border-color: #a6b9fb;
}

.container-error {
  border-color: #fba6a6;
}

.input-field::-webkit-input-placeholder {
  color: #9a9ca2;
}

.input-field::-moz-placeholder {
  color: #9a9ca2;
}

.input-field:-ms-input-placeholder {
  color: #9a9ca2;
}

.input-field::placeholder {
  color: #9a9ca2;
}

.input-field {
  width: 100%;
  border: none;
  outline: none;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black900);
}

/* 카운트 */

.count {
  display: flex;
  justify-content: right;
  font-size: 1.2rem;
  font-family: 'Pretendard';
  color: var(--color-black200);
  margin: 0.8rem 1.6rem;
}
.value {
  color: var(--color-green600);
  margin-right: 0.25rem;
}
