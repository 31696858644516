.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.title-text {
  margin-top: 2.4rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.0rem;
  line-height: 2.8rem;
  color: var(--color-black900);
}

.description-text {
  margin-top: 1.0rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
  white-space: pre-wrap;
}

.label-text {
  margin-left: 2.6rem;
  margin-bottom: 0.7rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black200);
}

.input-field-container {
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
}

.skip-text {
  text-align: center;
  margin-top: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  text-decoration: underline;
  color: var(--color-black500);
}