.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.label-container {
  margin-top: 2.4rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  white-space: pre-wrap;
}
.label {
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #87888d;
}
.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black800);

  p {
    margin: 0px;
    padding: 0px;
  }
}
.input-field-container {
  margin-top: 0.7rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
}

.cellphone-padding {
  margin-left: 0.8rem;
}
