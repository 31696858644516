.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.title-text {
  margin-left: 2rem;
  margin-top: 0.9rem;
  margin-bottom: 1.2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black800);
}

.title-hint-text {
  margin-left: 2rem;
  margin-bottom: 3.2rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: #848691;
}

.item-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.divider {
  margin-top: 2.6rem;
  margin-bottom: 2.6rem;
  margin-left: 2rem;
  margin-right: 1.2rem;
  height: 1px;
  background-color: #e9ecef;
}

.title-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2rem;
  margin-right: 1.2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black800);

  img {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.6rem;
  }
}

.sub-text {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: black;
}

.sub-hint-text {
  margin-left: 2rem;
  margin-bottom: 2.4rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: #868691;
}

.sub-item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 1.6rem;
  padding-right: 1.4rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black800);

  img {
    width: 2rem;
    height: 2rem;
    margin-right: 0;
  }
}

.sub-item-row-selected {
  border: 1px solid #00c471;
}

.sub-item-datetime1 {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black800);
  margin-bottom: 2px;
}

.sub-item-datetime2 {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #757781;
}

.button-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
}
.button-loading {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  pointer-events: none;
}

.sheet-terms-container {
  font-family: 'Pretendard';
}

.sheet-flex img {
  position: absolute;
}

.sheet-flex {
  display: flex;
  align-items: center;
  margin: 0.8rem 1.6rem;
}
.sheet-terms-title {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  font-size: 1.5rem;
  color: var(--color-black900);
  font-weight: 600;
}
.sheet-terms-sub-title {
  text-align: center;
  font-size: 1.5rem;
  color: var(--color-black200);
  font-weight: 600;
  font-family: 'Pretendard';
  margin: 2rem auto;
}

@media screen and (min-width: 1000px) {
  .container {
    height: calc(100vh - 12.5rem);
    padding-bottom: 0;
  }

  .button-container {
    position: sticky;
    bottom: 0;
    min-width: 320px;
    width: 100%;
  }
  .button-loading {
    position: sticky;
    bottom: 0;
    min-width: 320px;
    width: 100%;
    pointer-events: none;
  }
}
