.communityPosts {
  display: flex;
  flex-direction: column;
  margin-top: 2.8rem;
}

/* Spacer at the bottom of the post list */
.bottomSpacer {
  height: 4.8rem; /* Adjust the height to the amount of padding you need */
  background-color: transparent; /* Invisible box */
}
@media screen and (min-width: 1000px) {
  .bottomSpacer {
    height: 1rem;
  }
}
