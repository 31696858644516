.container {
  font-family: 'Pretendard';
  position: relative;
  padding-bottom: 8rem;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin: auto 2rem;
  margin-top: 2.4rem;
}

.text {
  font-size: 1.5rem;
  font-weight: 600;
  margin: auto 2rem;
  color: var(--color-black500);
  line-height: 2.1rem;
  margin-top: 1rem;
  margin-bottom: 3.7rem;
  white-space: pre-wrap;
}

.text-item {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.96rem;
  color: var(--color-black200);
  margin: auto 1.6rem;
  margin-bottom: 2.4rem;
}

.text-label {
  margin-left: 0.6rem;
  margin-bottom: 0.7rem;
}

.info-container {
  display: flex;
  flex-direction: column;
  margin: auto 1.6rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.68rem;
  color: var(--color-white700);
  margin-bottom: 2.8rem;
  white-space: pre-wrap;
}

.info-item {
  display: flex;
  margin-bottom: 0.6rem;
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
    margin-right: 0.2rem;
  }
}

.select-container {
  display: flex;
  align-items: center;
  margin: auto 2rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black900);
  img {
    margin: 0;
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
  }
}
.label {
  color: var(--color-black200);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.68rem;
  margin: 1.4rem 2rem;
  margin-bottom: 0.7rem;
}
.info {
  margin: auto 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-black500);
  margin-top: 1.4rem;
  margin-bottom: 2.3rem;
}

.button-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
}
@media screen and (min-width: 1000px) {
  .container {
    height: calc(100vh - 15.9rem);
  }
  .button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
    bottom: 0;
  }
}
