.container {
  font-family: 'Pretendard';
}

.title {
  margin: 2rem 2rem;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
}
