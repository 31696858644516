.payment-container {
  font-family: 'Pretendard';
  padding-bottom: 8rem;
}
.payment-top {
  display: flex;
  align-items: center;
  margin: 1.6rem 2rem;
  font-size: 2rem;
  font-weight: 600;
  white-space: pre-wrap;
  line-height: 2.8rem;
  img {
    width: 7.2rem;
    height: 7.2rem;
    border-radius: 50%;
  }
}
.payment-title {
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--color-black900);
  margin: auto 2rem;
  margin-bottom: 1.2rem;
}
.payment-sub-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-black200);
  margin: auto 2rem;
}

.payment-coupon-item {
  font-size: 1.4rem;
  color: var(--color-green600);
}

.payment-date {
  p {
    margin: 0.8 auto;
    padding: 0;
  }
  margin: auto 2rem;
  background-color: var(--color-white50);
  border-radius: 12px;
  margin-top: 1.6rem;
  margin-bottom: 2.8rem;
  img {
    width: 2.8rem;
    height: 2.8rem;
  }

  font-weight: bold;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.4rem;
}

.payment-coupon {
  margin-top: 2.4rem;
  margin-bottom: 3.8rem;
}

.payment-coupon-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-green600);
  font-weight: 600;
  font-size: 1.4rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
}
.payment-coupon-active {
  display: flex;
  align-items: center;
  background-color: var(--color-white50);
  margin: auto 2rem;
  padding: 1.4rem;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 8px;
  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.6rem;
  }
}
.payment-price {
  padding: 1.4rem;
  background-color: var(--color-white50);
  margin: auto 2rem;
  border-radius: 8px;
  margin-bottom: 3rem;
}
.payment-price-container {
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.payment-price-discount-container {
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-red600);
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.payment-price-tax-container {
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 600;
  color: #000;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.payment-price-payment-amount-container {
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 600;
  color: #000;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.line {
  background-color: var(--color-white200);
  height: 0.1rem;
  margin: 1.2rem auto;
}

.bold-line {
  height: 0.8rem;
  background-color: var(--color-white50);
}

.payment-price-title {
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--color-black900);
  margin: auto 2rem;
  margin-bottom: 1.2rem;
  margin-top: 2.4rem;
}

.payment-card {
  .payment-title {
    margin-top: 2.4rem;
  }
  margin-bottom: 2rem;
}

.payment-price-payment {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: bold;
}
.button-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
}
.payment-notice {
  li {
    padding: 0;
    margin: 0;
    margin: 0.6rem 2.5rem;

    color: var(--color-black200);
    font-size: 1.3rem;
    font-weight: 500;
  }
  img {
    width: 1.6rem;
    height: 1.6rem;
    rotate: 180deg;
  }
  .payment-title {
    margin-top: 2.4rem;
  }
}
.payment-notice-more {
  margin-top: 2rem;
  margin-bottom: 2.8rem;
  .line {
    margin: 0.8rem 2rem;
  }
  .payment-title {
    margin-top: 0;
  }
}
.payment-notice-more-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .payment-title {
    margin-bottom: 0;
  }
}

.payment-check-box {
  display: flex;
  align-items: center;
  margin: auto 2rem;
  margin-top: 1.6rem;
  margin-bottom: 1.3rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-black500);
  img {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.sheet-terms-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pretendard';
  span {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
  }
  img {
    margin: 0;
  }

  padding: 1.6rem 2.2rem 0 1.6rem;
}

.sheet-terms-content {
  font-family: 'Pretendard';
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  line-height: 1.96rem;
  p {
    font-size: 1.4rem;
    font-weight: 600;
    margin: auto 1.6rem;
    margin-bottom: 1.6rem;
  }
}

.sheet-button {
  position: relative;
  bottom: 0;
  width: 100%;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
}
@media screen and (min-width: 1000px) {
  .payment-container {
    padding-bottom: 0;
  }
  .button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
  }
}
