.title-text {
  margin-left: 2rem;
  margin-top: 1.6rem;
  margin-bottom: 1rem;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.36rem;
  color: var(--color-black800);
}

.title-hint-text {
  margin-left: 2rem;
  margin-bottom: 3.4rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: #848691;
  white-space: pre-wrap;
}

.image {
  text-align: center;

  img {
    width: 16rem;
    height: 16rem;
  }
}

.button-container {
  position: absolute;
  bottom: 0;
  width: 100vw;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: auto;
  margin-bottom: 3.2rem;
  box-sizing: border-box;
}
@media screen and (min-width: 1000px) {
  .container {
    height: calc(100vh - 8.8rem);
    padding-bottom: 0;
  }

  .button-container {
    position: sticky;
    bottom: 0;
    min-width: 320px;
    width: 100%;
  }
}
