.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.title-text {
  margin-top: 2.4rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.0rem;
  line-height: 2.8rem;
  color: var(--color-black900);
}

.sub-text {
  margin-top: 1.0rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black1000);
  white-space: pre-wrap;
}

.mate-example-image {
  margin-top: 2.0rem;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 24.6rem;
    height: 19.9rem;
  }
}

.description-text {
  margin-top: 1.6rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black200);
  white-space: pre-wrap;
}

.agreement-title-text {
  margin-top: 5.9rem;
  margin-bottom: 0.4rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-black200);
}

.agreement-row {
  margin-top: 0.8rem;
  margin-left: 2.0rem;
  margin-right: 2.0rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 0.6rem;
    width: 20px;
    height: 20px;
  }
}

.agreement-text {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--color-black900);
}

.agreement-view {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: auto;
  margin-right: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.432rem;
  text-decoration: underline;
  color: var(--color-white700);
}

.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  height: 4.14rem;
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black800);
}

.sheet-terms-button-container {
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16)); 
  box-sizing: border-box;  
}

.sheet-terms-button {
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 12px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background: var(--color-green600);
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  color: white;
}