.commentContainer {
  position: fixed;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1rem 1.6rem;
  border-top: 0.1rem solid var(--color-white200);
}
.commentMentionContainer {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: first baseline;
  background-color: #f3f3f3;
  border-radius: 2.4rem;

  padding: 1.4rem 1.6rem;
  width: 100%;
  vertical-align: middle;
}
.commentFieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.commentInput {
  padding: 1.4rem 1.6rem;
  width: 100%;
  background-color: var(--color-white50);
  border-radius: 2.4rem;
  border: none;
  outline: none;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.96rem;
}
.sendButton {
  margin-left: 0.8rem;
  background-color: var(--color-green50);
  border-radius: 50%;
  img {
    padding: 0.6rem;
    margin: auto;
  }
}
.deleteBtn {
  img {
    margin: 0;
    width: 1.4rem;
    height: 1.4rem;
  }
}
.editableInput {
  /* position: relative; */
  width: 100%;
  /* display: inline-block; */
  vertical-align: middle;
  border: none;
  outline: none;
  background-color: transparent;
  color: black;
  height: 2rem; /* 한 줄 높이에 맞게 조정 */
  padding: 0.4rem; /* 패딩 조정 */
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.96rem;
  white-space: normal; /* 기본 줄바꿈 처리 */
  word-wrap: break-word; /* 긴 단어가 줄을 넘어가지 않도록 처리 */
  word-break: break-word; /* 긴 단어가 줄바꿈 없이 넘어가지 않도록 처리 */
  overflow: hidden;
}
.commentMention {
  padding-right: 1rem;
  font-size: 1.3rem;
  color: var(--color-green600); /* 원하는 색상 */
  font-weight: bold; /* 강조 표시할 스타일 */
}
.commentInput::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

@media screen and (min-width: 1000px) {
  .commentContainer {
    position: absolute;
    bottom: 0;
  }
}
