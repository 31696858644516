.category-bar {
  height: 3.6rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem; /* Add padding to the right */
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  overflow-x: auto; /* Horizontal scrolling only */
  overflow-y: hidden; /* Prevent vertical scrolling */
  white-space: nowrap;
  box-sizing: border-box;

  /* Hide scrollbars */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.category-bar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.category-item {
  position: relative;
  height: 3.6rem;
  margin-right: 2.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #999dab;
  text-align: center; /* Center-aligns both text and underline */
}

.category-item-active {
  color: #1d1d1e;
}

.category-active {
  position: absolute;
  bottom: 0;
  width: 100%; /* Make the underline the same width as the content */
  border-bottom: 3px solid #1d1d1e;
  left: 0;
}

.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;
  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content-container {
  margin: auto 1.6rem;
  padding-bottom: 2.4rem;
}

.sheet-terms-content {
  display: inline-flex;
  gap: 0.5rem;
  width: auto-fit;
  align-items: center;
  border: 1px solid var(--color-black_transparent_12);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-radius: 24px;
  margin-top: 1.6rem;
  margin-right: 0.5rem;

  box-sizing: border-box;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1rem;
}

.circle {
  width: 2rem;
  height: 2rem;
  background-color: var(--color-white200);
  border-radius: 50%;
}
.circle-active {
  color: white;
  .circle {
    background-color: white;
  }
  background-color: var(--color-green600);
}
