.header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
}

.header img {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2rem;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: black;
}
.customTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.1rem;
  font-weight: 600;
  color: black;
}

.title {
  position: absolute;
  width: 100%;
  z-index: -1;
  /*
    flex: 1;
    position: relative;
    left: 0;
    */
  transform: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black800);
}

.back-button {
  cursor: pointer;
  margin-left: 2rem;
  z-index: 9999;
}

.language-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.2rem;
  width: 2.4rem;
  height: 2.4rem;
}

.language-button img {
  width: 2.4rem;
  height: 2.4rem;
}

.signup-button {
  margin-right: 2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black500);
}

.login-button {
  margin-right: 2rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black500);
}

.publishBtn {
  font-size: 1.5rem;
}
