.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
  
.form-container {
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
}

.label-title {
  margin-top: 2.4rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.0rem;
  line-height: 2.8rem;
  color: var(--color-black900);
}

.label-description {
  margin-top: 0.4rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-white800);
}

.divider {
  margin-top: 2.4rem;
  margin-bottom: 2.0rem;
  height: 8px;
  background-color: var(--color-white50);
}

.job-container {
  display: flex;
  flex-direction: column;
}

.job-category {
  margin-left: 2.0rem;
  margin-bottom: 6px;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black900);
}

.job-itemlist {
  scrollbar-width: none;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: nowrap; 
  gap: 8px;
  overflow-x: auto;
  padding-left: 2.0rem;
  padding-right: 0.8rem;
}

.job-item {
  flex: 0 0 auto;
  padding: 8px 16px 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  height: 3.7rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black900);
  box-sizing: border-box;
}

@media (min-width: 1000px) {
  .divider {
    min-width: 320px;
    width: 600px;
  }
  .job-container {
    min-width: 320px;
    width: 600px;

  }
  .job-itemlist {
    scrollbar-width: thin;
  }
}