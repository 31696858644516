.container {
  min-height: 100vh;
}

.type-bar {
  height: 3.6rem;
  margin-bottom: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 1.8rem auto;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.type-item {
  width: 100%;
  position: relative;
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #999dab;
}
.type-item-active {
  color: #1d1d1e;
  border-bottom: 3px solid;
}

.category-bar {
  width: 100vw;
  height: 3.6rem;
  padding-left: 1.6rem;
  border-bottom: 1px solid #e9ecef;
  padding-right: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  margin: 1.8rem auto;
  white-space: nowrap;
  box-sizing: border-box;
  gap: 1rem;

  img {
    margin-left: 0.6rem;
    margin-right: 0;
    width: 1.6rem;
    height: 1.6rem;
  }
}

.category-item {
  width: 100%;
  position: relative;
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #999dab;
}

.category-item-active {
  color: #1d1d1e;
}

.category-active {
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-bottom: 3px solid #1d1d1e;
}

.sort-bar {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-left: 2rem;
  padding-right: 1.4rem;
  /* margin-bottom: 2.4rem; */
  width: 100vw;
  height: 3.3rem;
  overflow-x: auto;
  white-space: nowrap;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.search-result-item {
  margin-top: 0.8rem;
}

.search-result-item {
  margin-left: 1.6rem;
  height: 4rem;
  display: flex;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: var(--color-black500);
}

.search-highlight-text {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: #00b76a;
  white-space: pre;
}

.search-general-text {
  white-space: pre;
}

.sheet-terms-container {
  font-family: 'Pretendard';

  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 1.6rem 2.2rem 1.4rem 1.6rem;
}

.sheet-terms-content {
  font-family: 'Pretendard';
  margin-left: 1.6rem;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
  margin-top: 1.4rem;
  line-height: 1.96rem;
}
.sheet-title {
  font-family: 'Pretendard';

  span {
    margin-left: 1.6rem;
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
    margin-top: 1.4rem;
    line-height: 1.96rem;
    font-weight: bold;
  }
}

@media screen and (min-width: 1000px) {
  .category-bar {
    width: 100%;
    overflow-y: hidden;
  }
  .sort-bar {
    width: 100%;
  }
}
