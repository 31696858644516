.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;
}
.label-container-title {
  margin-bottom: 1rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  white-space: pre-wrap;
}
.label-container {
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.label-sub-container {
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: var(--color-black900);
  font-weight: bold;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.text {
  margin-left: 2.2rem;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: var(--color-black200);
  white-space: pre-wrap;
  margin-bottom: 2.5rem;
}
.button-container {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  position: absolute;
  bottom: 0px;
  margin-top: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.toggle-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.toggle-item {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-item.selected {
  background-color: #d3f9d8;
  border-color: #34c759;
}
@media screen and (min-width: 1000px) {
  .container {
    margin-bottom: 0;
    padding-bottom: 7.1rem;
  }
  .button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
    bottom: 0;
    margin-top: 0;
  }
}
