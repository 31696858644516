.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.label-container {
  margin-top: 2.4rem;
  margin-bottom: 3.2rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.0rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  white-space: pre-wrap;
}

.label-country-name {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.0rem;
  line-height: 2.8rem;
  color: var(--color-green400);
}

.button-container {
  background-color: white;
  display: flex;
}

.sub-button {
  text-align: center;
  margin-top: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  text-decoration: underline;
  color: var(--color-black500);
}