.container {
  margin-bottom: 8rem;
}

.list-container {
  flex-grow: 1; /* 중간의 div가 남은 공간을 모두 차지하도록 함 */
  overflow-y: auto;
}

.alarm-count {
  font-family: 'Pretendard';
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 0.5rem;
  right: 5.5rem;
  background-color: var(--color-red400);
  color: white;
  font-size: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  line-height: 1.8;
}

.title-container {
  margin-top: 0.9rem;
  margin-bottom: 1.6rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mate-title-container {
  display: flex;
  flex-direction: row;
}

.title-text {
  position: relative;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black800);
  span {
    position: absolute;
    right: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: var(--color-green500);
  }
}

.title-text-no-active {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: #aaacb6;
}

.notification-icon {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #00b76a;
}

.title-trailer {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.432rem;
  text-decoration: underline;
  color: #9a9ca2;
}

.timezone-container {
  background-color: #f7f8f9;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 4rem;
  padding-left: 1.2rem;
  padding-right: 1.6rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  text-align: left;
  color: var(--color-black800);
  white-space: pre-wrap;
  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.6rem;
  }
}

.sub-text {
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.909rem;
  color: var(--color-black800);
}

.upcoming-meeting-none-text {
  margin-top: 4rem;
  margin-bottom: 6.7rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #999dab;
  text-align: center;
}

.my-meeting-history-button-container {
  display: flex;
  flex-direction: row;
  margin-top: 1.4rem;
  /*margin-bottom: 1.6rem;*/
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  gap: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.meeting-status-none-text {
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #999dab;
  text-align: center;
}

.meeting-find-button-container {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 4rem;
}

.meeting-status-container {
  margin-top: 1.6rem;
  padding-bottom: 4rem;
}
/* 시트 css */
.sheet-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-title {
  margin-left: 2rem;
  margin-bottom: 1.4rem;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}
.sheet-sub-title {
  margin-left: 2rem;
  margin-bottom: 1.4rem;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-content-container {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.2rem;
  padding: 2.4rem 1.6rem 2rem 1.6rem;
  border-radius: 16px;
  background-color: #f7f8f9;
}

.sheet-content-text {
  margin-bottom: 2rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.92rem;
  color: var(--color-black500);
}

.sheet-content-result-text {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: #00b76a;
}

.sheet-content-divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
  margin-top: 0.7rem;
  margin-bottom: 1.6rem;
}

.sheet-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  margin-bottom: 1.3rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black800);
}

.sheet-content-description-text {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2.4rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: #87888d;
  white-space: pre-wrap;
}
.button-container {
  display: flex;
  justify-content: space-between;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  gap: 0.5rem;
}

.popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 24px;
}

.popup-title {
  margin-bottom: 6px;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: var(--color-black900);
}

.popup-content {
  margin-bottom: 20px;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #888b93;
  white-space: pre-wrap;
}

.popup-button-container {
  display: flex;
  column-gap: 8px;
}

.popup-button {
  width: 74px;
  height: 42px;
  background-color: #00b76a;
  border-radius: 24px;
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 42px;
  color: #ffffff;
}

@media screen and (min-width: 1000px) {
  .container {
    min-height: calc(100vh - 5.7rem);
    overflow: hidden;
    margin-bottom: 0;
  }
}
