.coupon-container {
   background-color: var(--color-white50);
   margin-left: 1.6rem;
   margin-right: 1.6rem;
   padding: 1.4rem 1.2rem;
   border-radius: 8px;
  margin-bottom: 1.2rem;

}

.coupon-container img {
  margin-right: 0.75rem;
}

.coupon-top {
  display: flex;
  align-items: center;
}

.coupon-text {
  font-size: 1.4rem;
  color: var(--color-black900);
  font-weight: 600;
}

.coupon-bottom {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 0.8rem
}

.coupon-label {
  color: var(--color-red400);
  font-weight: bold;
  font-size: 1.4rem;
}

.coupon-price {
  color: var(--color-green600);
  font-size: 2.4rem;
  font-weight: bold;
  margin-left: 0.6rem;
}