.container {
  font-family: 'Pretendard';
  position: relative;
  text-align: center;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1rem;
  margin: auto 2rem;
  margin-top: 1.8rem;
  margin-bottom: 1.6rem;
}

.withdraw-container {
  margin: auto 2rem;
  input {
    outline: none;
    width: 100%;
    border: none;
    font-size: 5.6rem;
    text-align: center;
    line-height: 7.84rem;
    letter-spacing: 0.8rem;
    font-family: 'Pretendard';
    font-weight: 600;
  }
}

.text {
  font-size: 1.2rem;
  font-weight: 600;
  margin: auto 2rem;
  color: var(--color-black700);
  line-height: 1.56rem;
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  white-space: pre-wrap;
}

.img-container {
  display: flex;
  justify-content: center;
  margin-top: 5.8rem;
  img {
    margin: 0;
    width: 16rem;
    height: 16rem;
  }
}
.text-item {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.96rem;
  color: var(--color-black200);
  margin: auto 1.6rem;
}

.number-container {
  position: relative;
  top: 3.2rem;
  margin: auto 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3개의 열 */
  text-align: center;
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 3.64rem;
  span {
    padding: 1rem;
  }
  span:active {
    background-color: var(--color-white50);
  }
}

.money-container {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.68rem;
  background-color: var(--color-white100);
  padding: 0.8rem 1.2rem;
  width: 11.7rem;
  margin: 0 auto;
  border-radius: 6px;
}
.text {
  font-size: 1.2rem;
  color: #999dab;
}
.button-container {
  width: 100%;
  position: fixed;
  bottom: 3.2rem;
}
@media screen and (min-width: 1000px) {
  .container {
    height: calc(100vh - 5.6rem);
  }
  .button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
  }
}
