.contentContainer {
  position: relative;
}

.alarm-count {
  font-family: 'Pretendard';
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  top: 0.3rem;
  right: 1.5rem;
  background-color: var(--color-red400);
  color: white;
  font-size: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  line-height: 1.8;
}

@media screen and (min-width: 1000px) {
  .contentContainer {
    height: calc(100vh - 9.7rem);
    overflow-y: scroll;
  }
  .postsContainer {
    /* height: calc(100vh - 9.7rem); */
  }
}
