.select-container {
  font-family: 'Pretendard';
  box-sizing: border-box;
  cursor: pointer; /* 클릭 가능하게 보이도록 설정 */
  display: flex;
  justify-content: space-between;
  align-items: center; /* 수직 중앙 정렬 */
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  margin-bottom: 1.6rem;
  padding: 1.6rem;
  border-radius: 16px;
}
.text {
  font-size: 1.5rem;
  padding-right: 1rem;
  white-space: pre-wrap;
}
.time {
  margin-top: 0.4rem;
}
.select-container img {
  width: 2rem;
  height: 2rem;
}

/* 비활성 상태 스타일 */
.inactive {
  background-color: var(--color-white50); /* 비활성 색상 */
  color: #000000; /* 비활성 텍스트 색상 */
  font-weight: 600; /* 비활성 폰트 두께 */
  font-size: 1.6rem; /* 비활성 폰트 크기 */
}

/* 활성 상태 스타일 */
.active {
  background-color: var(--color-white50); /* 비활성 색상 */

  font-weight: 600; /* 활성 폰트 두께 */
  font-size: 1.6rem; /* 활성 폰트 크기 */
}

/* 테두리 스타일 */
.border-active {
  outline: 1.5px solid var(--color-green400); /* 활성 테두리 색상 */
}
