.container {
  display: flex;
  flex-direction: column;
  font-family: 'Pretendard';
  min-height: 100vh;
}
.label-container {
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  font-weight: bold;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.text {
  margin-left: 2rem;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: var(--color-black200);
  white-space: pre-wrap;
  margin-bottom: 2.5rem;
}

/* 바텀시트 */
/* 시트 css */
.sheet-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-title {
  margin-left: 2rem;
  margin-bottom: 1.4rem;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-content-container {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.2rem;
  padding: 2.4rem 1.6rem 2rem 1.6rem;
  border-radius: 16px;
  background-color: #f7f8f9;
}

.sheet-content-text {
  margin-bottom: 2rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.92rem;
  color: var(--color-black500);
}

.sheet-content-result-text {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: #00b76a;
}

.sheet-content-divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
  margin-top: 0.7rem;
  margin-bottom: 1.6rem;
}

.sheet-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  margin-bottom: 1.3rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black800);
}

.sheet-content-description-text {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2.4rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: #87888d;
  white-space: pre-wrap;
}
.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black800);

  p {
    margin: 0px;
    padding: 0px;
  }
}

.input-field-container {
  margin-left: 1.6rem;
  flex-direction: row;
}

.payment-history {
  margin: 1.2rem 1.6rem;
}
.payment-history-date {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.payment-item {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-white50);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.2rem;
}
.payment-item span {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  font-size: 1.5rem;
}
.payment-name {
  display: flex;
  flex-direction: column;
  color: var(--color-black200);
}
.payment-data {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-weight: 600;
}

.line {
  width: 100%;
  height: 0.1rem;
  margin: 2.4rem auto;
  background-color: var(--color-white200);
}
@media screen and (min-width: 1000px) {
  .container {
    min-height: 100vh;
  }
}
