.title-text {
  margin-left: 2.0rem;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  text-align: left;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.36rem;
  color: var(--color-black800)
}

.label-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.7rem;
  margin-left: 2.6rem;
  margin-right: 2.4rem;
}

.label {
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #87888D;
}

.input-field-container {
  display: flex;
  margin-top: 0.7rem;
  margin-bottom: 3.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}