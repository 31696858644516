.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.label-container {
  margin-top: 2.4rem;
  margin-bottom: 3.0rem;
  margin-left: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2.0rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  white-space: pre-wrap;
}

.label {
  margin-left: 2.6rem;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: #878893;
}

.input-field-container {
  margin-top: 0.7rem;
  margin-bottom: 3.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
}

.button-container {
  background-color: white;
  display: flex;
}