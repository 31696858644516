.container {
  min-height: 100vh;
}

.category-bar {
  width: 100vw;
  height: 3.6rem;
  margin-bottom: 1.6rem;
  padding-left: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  overflow-x: auto;
  white-space: nowrap;
  box-sizing: border-box;
  scrollbar-width: none;

  img {
    margin-right: 2rem;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.category-item {
  position: relative;
  height: 3.6rem;
  margin-right: 2.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #999dab;
}

.category-item-active {
  color: #1d1d1e;
}

.category-active {
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-bottom: 3px solid #1d1d1e;
}
.profile-list-container {
  position: relative;
  margin: 1.6rem;
}
.profile-list-item {
  display: flex;
  background-color: var(--color-white50);
  border-radius: 1.2rem;
  padding: 1.2rem 1rem;
  margin: 1rem auto;
  img {
    width: 5.4rem;
    height: 54px;
    margin-right: 1rem;
  }
}
.profile-list-item:active,
.profile-list-item:hover {
  outline: 0.2rem solid var(--color-black_transparent_6);
}
.profile-item-thumbnail {
  border-radius: 50%;
  img {
    border-radius: 50%;
  }
}
.profile-item-tag {
  background-color: var(--color-black500);
  color: white;
  padding: 0.4rem 0.25rem;
  border-radius: 0.4rem;
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.profile-item-nickname {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.95rem;
  margin-bottom: 0.5rem;
}

.profile-item-company {
  font-size: 1.2rem;
  line-height: 1.56rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.profile-item-message {
  font-size: 1.2rem;
  line-height: 1.56rem;
  color: var(--color-white800);
}

.profile-item-bookmark {
  position: absolute;
  right: 0;
  img {
    width: 2rem;
    height: 20px;
  }
}

.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;
  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content-container {
  margin: auto 1.6rem;
  padding-bottom: 2.4rem;
}

.sheet-terms-content {
  display: inline-flex;
  gap: 0.5rem;
  width: auto-fit;
  align-items: center;
  border: 1px solid var(--color-black_transparent_12);
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-radius: 24px;
  margin-top: 1.6rem;
  margin-right: 0.5rem;

  box-sizing: border-box;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1rem;
}

.circle {
  width: 2rem;
  height: 2rem;
  background-color: var(--color-white200);
  border-radius: 50%;
}

/* element */
.flex {
  display: flex;
  gap: 0.2rem;
}
.active {
  color: white;
  .circle {
    background-color: white;
  }
  background-color: var(--color-green600);
}
@media screen and (min-width: 1000px) {
  .container {
    min-height: 100vh;
  }
  .category-bar {
    width: 100%;
  }
}
