.react-calendar {
  font-family: 'Pretendard';
  margin: auto 1.6rem;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  margin: auto 1.6rem;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

/* 월화수목금 텍스트 */
.react-calendar__month-view__weekdays__weekday {
  width: 4.8rem;
  height: 4.8rem;
  line-height: 4.8rem;
  font-size: 1.4rem;
  color: var(--color-black500);
}

.react-calendar abbr {
  text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

/* 선택할 수 없는 부분 */
.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: var(--color-black_transparent_24);
}

/* 요일 숫자 텍스트 */
.react-calendar__tile {
  background-color: transparent;
  width: 4.8rem;
  height: 4.8rem;
  line-height: 4.8rem;
  font-size: 1.4rem;
  font-weight: bold;
  /* color: var(--color-black500); */
  font-family: 'Pretendard';
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--color-green600);
  color: white;
  border-radius: 50%;
}

.react-calendar__tile--now {
  color: var(--color-green600);
}

.react-calendar__tile--active {
  background-color: var(--color-green600);
  color: white;
  border-radius: 50%;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
  background-color: var(--color-green600);
  color: white;
  border-radius: 50%;
}

/* 적용한 css 부분 */

/* 달력 연도 월 텍스트 */
.react-calendar__navigation__label__labelText--from {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Pretendard';
}

/* 연도 버튼 지우기 */
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__prev2-button {
  display: none;
}

/* 월 버튼 커스텀 */

.react-calendar__navigation__next-button {
  position: relative;
  right: -1.6rem;
  font-size: 2.5rem;
}
.react-calendar__navigation__prev-button {
  position: relative;
  left: -1.6rem;
  font-size: 2.5rem;
}
