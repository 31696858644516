.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
}

.title-text {
  margin-top: 2.4rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  white-space: pre-wrap;
}

.description-text {
  margin-top: 1rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
  white-space: pre-wrap;
}

.timezone-container {
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 16px 14px 14px 12px;
  height: 40px;
  background-color: var(--color-white50);
  border-radius: 8px;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black900);
  white-space: pre-wrap;
}

.item-column {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black900);

  img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
}

.item-text {
  width: 3.3rem;
  margin-right: 18px;
}

.item-divider {
  margin-left: 6px;
  margin-right: 6px;
}

.next-button-container {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.sheet-terms-container {
  margin-top: 2.2rem;
  margin-left: 2rem;
  padding-bottom: 2.4rem;
  display: flex;
  flex-direction: row;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black900);
  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black900);

  p {
    margin: 0px;
    padding: 0px;
  }
}
