.box-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  background-color: var(--color-white50);
  border-radius: 8px;
  font-family: 'Pretendard';
  margin: 0.8rem 1.6rem;
}
.box-flex{
  display: flex;
  align-items: center;
}
.box-container img {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.6rem;
}

.box-btn {
  font-family: 'Pretendard';
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: var(--color-green600);
  font-weight: bold;
}