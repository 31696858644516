.search-bar-container {
  margin-left: 1rem;
  margin-top: 1.2rem;
  width: calc(100% - 1rem);
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-right: 0.6rem;
  }
}

.search-textfield {
  margin-right: 1.2rem;
  width: 100%;
  height: 4.2rem;
  background-color: #f7f8f9;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  box-sizing: border-box;

  .search-icon {
    width: 24px;
    height: 24px;
    margin-left: 0rem;
    margin-right: 0.6rem;
  }

  .clear-icon {
    width: 24px;
    height: 24px;
    margin-left: 1.2rem;
    margin-right: 0rem;
  }
}

.input-field::-webkit-input-placeholder {
  color: #9a9ca2;
}

.input-field::-moz-placeholder {
  color: #9a9ca2;
}

.input-field:-ms-input-placeholder {
  color: #9a9ca2;
}

.input-field::placeholder {
  color: #9a9ca2;
}

.input-field {
  width: 100%;
  border: none;
  outline: none;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--color-black800);
  background-color: #f7f8f9;
}
@media (min-width: 1000px) {
  .container {
    min-width: 320px;
    width: 100%;
  }
}
