/* swiper 커스텀 css 입니다. */

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 0.8rem);
  top: var(--swiper-pagination-top, auto);
  right: 1rem;
  width: 3rem;
  height: 2rem;
  border-radius: 25%;
  background-color: #00000024;
  font-size: 1.25rem;
  letter-spacing: -0.1rem;
  line-height: 2rem;
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 1;
}
