.container {
  font-family: 'Pretendard';
  padding-bottom: 8rem;
}
.product-top {
  font-size: 2rem;
  font-weight: bold;
  margin: auto 2rem;
}
.product-price-container {
  margin: auto 2rem;
  border: 2px solid var(--color-white200);
  border-radius: 16px;
  padding: 1.2rem;
  .product-sub-title {
    font-size: 1.2rem;
    font-weight: 600;
  }
}
.product-title {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 2rem;
  margin-bottom: 1rem;
}
.product-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-green50);
  padding: 1.4rem;
  border-radius: 8px;
}

.product-sale {
  color: var(--color-green700);
  margin-left: 0.5rem;
}

.product-sub-title {
  display: flex;
  align-items: center;
  color: var(--color-black900);
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.5rem;
  }
}

.product-coupon-price {
  text-align: right;
  color: var(--color-red400);
  font-weight: bold;
  font-size: 1.6rem;
  /* margin-top: 1rem; */
}

.product-price {
  font-weight: bold;
  font-size: 1.4rem;
  color: var(--color-black900);
}
.product-coupon {
  color: var(--color-green600);
}
.product-text {
  font-size: 1.2rem;
  color: var(--color-black200);
  margin: auto 2rem;
  margin-bottom: 2rem;
}
.product-meeting-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-black500);
  margin-top: 1.6rem;
  margin-bottom: 1.2rem;
}
.product-meeting-mate-time {
  border: 1px solid var(--color-black_transparent_6);
  padding: 0.7rem 1.4rem;
  border-radius: 24px;
}

.product-select-container {
  .product-text {
    margin-bottom: 2rem;
  }
}

.product-text-container {
  margin-bottom: 3.5rem;
}

.product-text {
  display: flex;
  justify-content: space-between;
}

.product-file-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-white700);
  margin-bottom: 0.7rem;
}

.product-text-area-focused {
  border-color: var(--color-green300);
}

.product-file-container {
  margin-bottom: 4.5rem;
}

.product-file-item {
  margin: auto 2rem;
}

.product-file {
  width: 100%;
  border: 1px solid var(--color-black_transparent_12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  padding: 1.5rem auto;
  margin-bottom: 1rem;
}
.input-file-button {
  text-align: right;
  background-color: var(--color-white50);
  border-radius: 4px;
  color: var(--color-blue500);
  cursor: pointer;
  padding: 1rem;
  margin-right: 0.6rem;
  border-radius: 8px;
  font-size: 1.5rem;
  font-weight: 600;
}

.file-name {
  padding: 1.5rem;
  font-size: 1.5rem;
  color: var(--color-white700);
  font-weight: 600;
}

/* 날짜선택 */
.sub-text {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: black;
}

.sub-hint-text {
  margin-left: 2rem;
  margin-bottom: 2.4rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: #868691;
}

.sub-item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.2rem;
  margin-right: 2rem;
  margin-bottom: 1.6rem;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 1.6rem;
  padding-right: 1.4rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black800);

  img {
    width: 2rem;
    height: 2rem;
    margin-right: 0;
  }
}

.sub-item-row-selected {
  border: 1px solid #00c471;
}

.sub-item-datetime1 {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-black800);
  margin-bottom: 2px;
}

.sub-item-datetime2 {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #757781;
}

.button-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
}

.sheet-terms-container {
  font-family: 'Pretendard';
}

.sheet-flex img {
  position: absolute;
}

.sheet-flex {
  display: flex;
  align-items: center;
  margin: 0.8rem 1.6rem;
}
.sheet-terms-title {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  font-size: 1.5rem;
  color: var(--color-black900);
  font-weight: 600;
}
.sheet-terms-sub-title {
  text-align: center;
  font-size: 1.5rem;
  color: var(--color-black200);
  font-weight: 600;
  font-family: 'Pretendard';
  margin: 2rem auto;
}

.button-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
}

.back-button-container {
  display: flex;
  justify-content: space-between;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  gap: 0.5rem;
}
.back-sheet-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}
.back-sheet-title {
  margin-left: 2rem;
  margin-bottom: 1.4rem;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}
.back-sheet-sub-title {
  margin-left: 2rem;
  margin-bottom: 1.4rem;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}
@media screen and (min-width: 1000px) {
  .container {
    height: calc(100vh - 8rem);
  }
  .product-file-item {
    padding-bottom: 17.3rem;
  }
  .button-container {
    position: sticky;
    bottom: 0;
    min-width: 320px;
    width: 100%;
  }
}
