.container {
  font-family: 'Pretendard';
  margin-bottom: 4.8rem;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.content {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.1rem;
  margin: 0;
  padding: 0;
  margin-left: 2.2rem;
  white-space: pre-wrap;
}

.faq-container {
  margin: auto 2rem;
}

.faq-more-container {
  margin-top: 4.0rem;
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 24px;
  background-color: var(--color-white50);
  border-radius: 12px;
  color: var(--color-black500);

  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.4rem;
  }
}

.faq-more-text {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1rem;
  white-space: pre-wrap;
  margin-bottom: 2.1rem;
}

.faq-more-link {
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: underline;
  line-height: 2.1rem;
  margin-bottom: 2.1rem;
  display: flex;
  align-items: center;
}