.tabsItem {
  display: flex;
  justify-content: space-between; /* Ensures title and icon are on opposite sides */
  align-items: center;
  padding: 8px 10px; /* Padding inside the tab */
  cursor: pointer;
  border-bottom: 1px dotted lightblue; /* Adds dotted line for separation */
}

.tabsText {
  font-size: 16px;
  margin-left: 15%; /* Removes unnecessary left margin */
}

.tabsTitle {
  font-weight: 500;
  font-size: 16px;
}

.iconButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButton:hover {
  color: #007bff;
}

.chevronIcon {
  font-size: 24px;
}

/* Flex adjustments for the text and the button */
.tabsContent {
  display: flex;
  justify-content: space-between; /* Space between text and chevron */
  align-items: center;
  width: 100%;
}
