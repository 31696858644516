.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.form-container {
  flex: 1;
  overflow-y: auto;
}

.profile-image-container {
  margin-top: 1.6rem;
  display: flex;
  flex-direction: row;
  height: 8.8rem;
}

.profile-image-thumbnail {
  margin-left: 2rem;

  img {
    width: 8.8rem;
    height: 8.8rem;
    border-radius: 50%;
  }
}

.profile-image-thumbnail-mate {
  margin-left: auto;
  margin-right: auto;
  position: relative;

  img {
    width: 8.8rem;
    height: 8.8rem;
    border-radius: 50%;
  }
}

.profile-image-camera-icon {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: -14px;
  top: 0;

  img {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
  }
}

.profile-information {
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  margin-left: 1.2rem;
}

.profile-nickname {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black900);
}

.profile-company {
  margin-bottom: 5px;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: var(--color-black900);
}

.profile-job {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--color-white700);
}

.example-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1.2rem;
  margin-top: 1.6rem;
  margin-bottom: 1.2rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  height: 4.8rem;
  border-radius: 10px;
  background-color: var(--color-white50);
}

.example-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-white200);
  margin-right: 6px;
}

.example-description-text {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black1000);
  margin-right: 1rem;
}

.example-view {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  text-decoration: underline;
  color: var(--color-black1000);
}

.introduce-container {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.introduce-title {
  margin-bottom: 1.2rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.92rem;
  color: var(--color-black900);
  white-space: pre-wrap;
}

.introduce-description {
  margin-left: 2rem;
  margin-bottom: 1.6rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.68rem;
  color: var(--color-black200);
}

.introduce-description-link {
  margin-left: 2.6rem;
  margin-bottom: 0.7rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black200);
}

.introduce-content {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;
  position: relative;
}

.introduce-add-button {
  height: 4.8rem;
  margin-left: 2rem;
  margin-right: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 4.8rem;
  color: var(--color-black900);

  img {
    width: 20px;
    height: 20px;
  }
}
.introduce-delete-button {
  position: absolute;
  right: 0;
  top: 1rem;
  z-index: 1;
  img {
    width: 20px;
    height: 20px;
  }
}

.divider {
  margin-top: 0.4rem;
  height: 8px;
  background-color: var(--color-white100);
}

.button-container {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  height: 8rem;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.sheet-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-title {
  margin-left: 2rem;
  margin-bottom: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-content {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #1d1d1e;
  white-space: pre-wrap;
}

.sheet-button-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 5px;
}
