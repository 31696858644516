.container {
  font-family: 'Pretendard';
}

.title {
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-black800);
  line-height: 1.96rem;
  margin: auto 2rem;
  margin-top: 2.4rem;
}

.info {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.68rem;
  color: var(--color-white600);
  margin: auto 2rem;
  margin-top: 0.7rem;
}

.type-container {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1rem;
  margin: 1.6rem 2rem;
}

.type-item {
  display: inline-flex;
  gap: 0.5rem;
  width: auto-fit;
  align-items: center;
  border: 1px solid var(--color-black_transparent_12);
  padding: 0.8rem 1.4rem;
  border-radius: 24px;
  margin-right: 0.5rem;
}

.active {
  background-color: var(--color-green600); /* 원하는 배경 색상 */
  color: white;
}

.button-container {
  width: 100%;
  position: fixed;
  bottom: 3.2rem;
}
@media screen and (min-width: 1000px) {
  .button-container{
    min-width: 320px;
    width: 600px;

  }
 

}