.container {
  font-family: 'Pretendard';
  position: relative;
  height: 100vh;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin: auto 1.6rem;
  margin-top: 2.4rem;
}

.text {
  font-size: 1.5rem;
  font-weight: 600;
  margin: auto 1.6rem;
  color: var(--color-black500);
  line-height: 2.1rem;
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  white-space: pre-wrap;
}

.info-box {
  margin: auto 1.6rem;
  padding: 2.4rem 1.8rem;
  background-color: var(--color-white50);
  border-radius: 12px;
  img {
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: 1.6rem;
  }
}
.info-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  margin-bottom: 1.6rem;
}
.info-text {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  color: var(--color-black500);
  white-space: pre-wrap;
}

.button-container {
  width: 100%;
  position: fixed;
  bottom: 3.2rem;
}

.select-container {
  margin: auto 1.6rem;
}

.info-container {
  display: flex;
  flex-direction: column;
  margin: auto 1.6rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.68rem;
  color: var(--color-white700);
  margin-bottom: 2.8rem;
  white-space: pre-wrap;
}

.info-item {
  display: flex;
  margin-bottom: 0.6rem;
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
    margin-right: 0.2rem;
  }
}

.sheet-terms-container {
  font-family: 'Pretendard';
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 1.6rem 2.2rem 1.4rem 1.6rem;
}

.sheet-terms-content {
  font-family: 'Pretendard';
  margin-left: 1.6rem;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
  margin-top: 1.4rem;
  line-height: 1.96rem;
}
@media screen and (min-width: 1000px) {
  .container {
  }
  .button-container {
    position: absolute;
    min-width: 320px;
    width: 100%;
  }
}
