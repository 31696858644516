.container {
  font-family: 'Pretendard';
  min-height: 100vh;
}

.profile-container {
  text-align: center;
  border-bottom: 8px solid var(--color-white50);
  img {
    width: 8rem;
    height: 8rem;
    margin: 0;
    margin-top: 0.8rem;
    margin-bottom: 2rem;
    border-radius: 50%;
  }
}

.profile-nickname {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.52rem;
  margin-bottom: 0.8rem;
}

.profile-info {
  font-size: 1.4rem;
  font-weight: 500;
  margin: auto 2rem;
  line-height: 1.96rem;
  color: var(--color-black500);
  margin-bottom: 2.8rem;
}
.profile-type-container {
  display: flex;
  justify-content: space-between;
}
.profile-type {
  display: flex;
  flex-direction: column;
  span {
    padding: 1.6rem 2rem;
    color: var(--color-white700);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.96rem;
  }
}
.profile-type-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* 자식 요소를 우측 정렬 */
  text-align: right; /* 텍스트를 우측 정렬 */
  span {
    padding: 1.6rem 2rem;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.1rem;
  }
}

.sheet-terms-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pretendard';
  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 1.6rem 2.2rem 0 1.6rem;
}

.sheet-terms-content {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  font-family: 'Pretendard';
}

.button-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  background-color: white;
  /* filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16)); */
}

.report-title {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.96rem;
  margin: auto 1.6rem;
  margin-bottom: 1.2rem;
}

.report-select {
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.96rem;
  img {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.report-description {
  font-size: 1.4rem;
  color: var(--color-black500);
  line-height: 1.96rem;
  font-weight: 600;
  margin: auto 1.6rem;
  white-space: pre-wrap;
  margin-top: 1.2rem;
  li {
    padding: 0;
    margin: 0;
    margin-left: 0.5rem;
  }
}

@media screen and (min-width: 1000px) {
  .container {
    min-height: 100vh;
  }
  .button-container {
    min-width: 320px;
    width: 600px;
  }
}
