.result-container {
  position: relative;
  font-family: 'Pretendard';
  border-bottom: 1px solid var(--color-white200);
  margin: auto 2rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  /* padding-bottom: 2rem; */
}

.result-info-container {
  display: flex;
  align-items: flex-start;
}
.result-bookmark {
  position: absolute;
  right: 0;
  margin-top: 0.5rem;
  img {
    width: 2rem;
    height: 2rem;
    margin: 0 auto;
  }
}
.result-info-item {
  display: flex;
  flex-direction: column;
  margin-top: 0.7rem;
}
.result-info-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
}
.result-info-sub-title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.8rem;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
}
.result-info-text {
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-white700);
}
.result-info {
  font-weight: 500;
  font-size: 1.4rem;
  margin-top: 0.8rem;
  /* margin-bottom: 2.7rem; */
  line-height: 1.96rem;
  span {
    color: var(--color-green600);
  }
}

.result-rating {
  display: flex;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  font-size: 1.2rem;
  font-weight: 600;
  align-items: flex-start;
  margin-bottom: 2.4rem;
  img {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.2rem;
  }
  .result-review {
    border-bottom: 1px solid;
  }
}

.result-rating-count {
  margin-right: 0.8rem;
}

.result-profile-img {
  width: 7.2rem;
  height: 7.2rem;
  margin-right: 1.6rem;
  border-radius: 50px;
  border: 2px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  object-fit: cover;
}

.result-info-number {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.result-count {
  position: relative;
  bottom: 0;
  font-size: 1.2rem;
  background-color: var(--color-green70);
  border-radius: 6px;
  padding: 0.7rem 1.2rem;
  font-weight: 600;
  .result-bold-text {
    font-weight: 700;
    color: var(--color-green600);
  }
}
.result-price {
  font-size: 1.6rem;
  font-weight: bold;
}
.result-info-number-type {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white50);
  border-radius: 8px;
  height: 4.2rem;

  .result-count {
    border-radius: 0;
    font-size: 1.3rem;
    background-color: var(--color-white50);
  }
  .result-line {
    width: 0.1rem;
    height: 1.7rem;
    background-color: var(--color-white300);
  }
}
