.profile-container {
  display: flex;
  flex-direction: row;
  margin-top: 4.8rem;
  margin-left: 1.6rem;
  margin-right: 2.4rem;
  img {
    margin-right: 1.2rem;
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    border: 1.4px solid rgba(0, 0, 0, 0.05);
  }
}

.welcome-text {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.36rem;
  color: var(--color-black800);
}

.mate-active-status-container {
  margin-top: 1.4rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
  flex-direction: row;
  background-color: #deffdb;
  height: 5.2rem;
  align-items: center;
  justify-content: space-between;
}

.mate-active-text {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: #009b5a;
}

.my-main-text {
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: #009b5a;
}

.card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-column-box {
  width: 100vw;
  padding-left: 1.6rem;
  padding-right: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f4f5f6;
  border-radius: 12px;
  height: 9.1rem;
}

.card-count-text {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
  margin-right: 0.6rem;
}

.card-meeting-requests {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  background-color: #00e786;
  border-radius: 24px;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.card-text {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
}

.arrow-right-icon {
  margin-left: auto;
  margin-right: 0px;
  width: 1.4rem;
  height: 1.4rem;
}

.card-menu-container {
  margin-left: 2.4rem;
  margin-right: 2.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5.1rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: var(--color-black800);

  img {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-right: 0.9rem;
  }
}

.card-menu-add-product {
  margin-left: auto;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  height: 3.1rem;
  border-radius: 24px;
  border: 1px solid rgba(0, 179, 104, 0.12);
  background-color: #deffdb;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 3.1rem;
  color: #00a15d;
}

.divider {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  height: 7px;
  background-color: #f7f8f9;
}

.popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
}

.popup-title {
  margin-bottom: 6px;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: var(--color-black900);
}

.popup-content {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #888b93;
  white-space: pre-wrap;
  color: black;
  text-align: center;
}

.popup-button {
  display: flex;
  justify-content: space-between;
  width: 17.4rem;
  margin-top: 2rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.24rem;
}
.btn:last-child {
  color: var(--color-green800);
}

@media screen and (min-width: 1000px) {
  .container {
    min-height: calc(100vh - 5.7rem);
    overflow: hidden;
  }
}
