.fab {
  position: fixed;
  bottom: 6.5rem;
  right: 0;
  margin: 0 1.6rem;
  background-color: #4caf50; /* MUI의 green[500] 대체 */
  border: none;
  border-radius: 50%;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  img {
    width: 2.8rem;
    height: 2.8rem;
    margin-right: 0;
  }
}

.fab:hover {
  background-color: #388e3c; /* Hover 시 녹색 변경 */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.fab:active {
  background-color: #2e7d32; /* 클릭 시 녹색 변경 */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

@media screen and (min-width: 1000px) {
  .fab {
    position: absolute;
    bottom: 1.1rem;
    right: 1.6rem;
    margin-top: 0;
  }
}
