.card-status-in-review {
  padding: 0.6rem 1.0rem 0.6rem 1.0rem;
  background-color: var(--color-green600);
  border-radius: 6px;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.82rem;
  text-align: center;
  color: white;
}

.card-status-active {
  padding: 0.6rem 1.0rem 0.6rem 1.0rem;
  background-color: #DEFFDC;
  border-radius: 6px;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.82rem;
  text-align: center;
  color: #00A05D;
}

.card-status-inactive {
  padding: 0.6rem 1.0rem 0.6rem 1.0rem;
  background-color: #FFF4F1;
  border-radius: 6px;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.82rem;
  text-align: center;
  color: #FF4040
}

.card-status-request-suspension {
  padding: 0.6rem 1.0rem 0.6rem 1.0rem;
  background-color: #EFF1F3;
  border-radius: 6px;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.82rem;
  text-align: center;
  color: var(--color-black500);
}

.card-status-reject {
  padding: 0.6rem 1.0rem 0.6rem 1.0rem;
  background-color: #BEC1C6;
  border-radius: 6px;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.82rem;
  text-align: center;
  color: #FFFFFF
}