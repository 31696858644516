.profile-container {
  display: flex;
  flex-direction: row;
  margin-top: 0.8rem;
  margin-left: 1.6rem;
  margin-right: 2.4rem;

  img {
    margin-right: 1.2rem;
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    border: 1.4px solid rgba(0, 0, 0, 0.05);
  }
}

.welcome-text {
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.36rem;
  color: var(--color-black800);
}

.card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-column-box {
  width: 100vw;
  padding-left: 1.6rem;
  padding-right: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f4f5f6;
  border-radius: 12px;
  height: 9.1rem;
}

.card-text {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
}

.arrow-right-icon {
  margin-left: auto;
  margin-right: 0px;
  width: 1.4rem;
  height: 1.4rem;
}

.card-menu-container {
  margin-left: 2.4rem;
  margin-right: 2.4rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 5.1rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: var(--color-black800);

  img {
    width: 5.6rem;
    height: 2.8rem;
  }
}

.card-menu-add-product {
  margin-left: auto;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  height: 3.1rem;
  border-radius: 24px;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: var(--color-green600);
  display: flex;
  align-items: center;
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
    margin-left: 0.2rem;
  }
}

.divider {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  height: 7px;
  background-color: #f7f8f9;
}
