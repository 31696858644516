.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.form-container {
  overflow-y: auto;
}

.profile-image-container {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  height: 8rem;
  position: relative;
}

.profile-image-thumbnail {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  img {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
  }
}

.profile-image-camera-icon {
  position: absolute;
  right: -14px;
  top: 0;

  img {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
  }
}

.label-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.7rem;
  margin-left: 2.6rem;
  margin-right: 2.6rem;
}

.label {
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #87888d;
}

.label-prefix {
  margin-left: auto;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.313rem;
  color: #00b76a;
}

.input-field-container {
  margin-left: 2rem;
  margin-right: 2rem;
}

.modify-button-container {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  width: 100%;
  height: 8rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
  span {
    margin-left: 2rem;
    margin-bottom: 0.4rem;
    font-family: 'Pretendard';
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.52rem;
    color: var(--color-black800);
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #1d1d1e;
}

.select-container {
  margin: auto 2rem;
}
@media screen and (min-width: 1000px) {
  .main-container {
    height: calc(100vh - 10.4rem);
  }
  .container {
    position: relative;
    height: 100%;
  }
  .modify-button-container {
    position: sticky;
    min-width: 320px;
    width: 100%;
    filter: none;
  }
}
