/* Ensure the entire page takes up the full height */
* {
  font-family: 'Pretendard';
}
.pageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto; /* Full viewport height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: hidden; /* Avoid accidental vertical scroll issues */
  padding-bottom: 3rem;
  white-space: pre-wrap;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.1rem;
  padding: 1.2rem 1.6rem;
  padding-bottom: 0.8rem;
}

.article {
  font-size: 1.3rem;
  line-height: 1.95rem;
  margin: auto 1.6rem;
  margin-bottom: 2.4rem;
  color: var(--color-black700);
}
.group {
  font-size: 1.2rem;
  line-height: 1.44rem;
  margin: auto 1.6rem;
  color: var(--color-white800);
  font-weight: 500;
  margin-bottom: 1rem;
  div {
    display: flex;
    gap: 1rem;
  }
}

.frame {
  margin: 1.6rem;
}

.text_wrapper {
  margin-right: 1rem;
  color: var(--color-black800);
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.68rem;
}
.commentAdd {
  color: var(--color-green600);
  margin-left: 1.6rem;
  display: flex;
  align-items: center;
  img {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0;
    margin-right: 0.4rem;
  }
}
.commentText {
  display: flex;
}
.commentsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-black800);
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.68rem;
}

.iconContainer {
  img {
    margin: 0;
    margin-left: 2rem;
  }
}

.floatingCommentsFields {
  opacity: 1;
}
.activeCommentsFields {
  opacity: 0.5;
  pointer-events: none;
}

.divider {
  height: 0.1rem;
  background-color: var(--color-white200);
  margin: auto 1.6rem;
}

/* sheet,popup */
.sheet-terms-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pretendard';

  span {
    font-size: 1.8rem;
    font-weight: 600;
  }
  img {
    margin: 0;
  }
  padding: 2.2rem 1.6rem 1.4rem 1.6rem;
}

.text {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  margin: auto 1.6rem;
  margin-bottom: 1.2rem;
}

.sheet-terms-content {
  font-family: 'Pretendard';
}

.report-select {
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.96rem;
  padding: 0.4rem 0;

  img {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.button-container {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 1.2rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin: auto 1.6rem;
  gap: 0.5rem;
}

.popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 24px;
}

.popup-image {
  margin: 0 auto;
  width: 74px;
  height: 91px;
}

.popup-title {
  margin-bottom: 6px;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: var(--color-black900);
}

.popup-content {
  margin-bottom: 20px;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #888b93;
  white-space: pre-wrap;
}

.popup-button {
  width: 180px;
  height: 42px;
  background-color: #00b76a;
  border-radius: 24px;
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 42px;
  color: #ffffff;
}

@media screen and (min-width: 1000px) {
  .pageContainer {
    height: 100vh;
    padding: 0;
    display: block;
  }
  .commentContainer {
    /* 여기 계산해서 써라! */
    height: calc(100% - 320px);
    overflow-y: scroll;
    position: relative;
  }
}
