.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  

}

.form-container {
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
}

.label-container {
  display: flex;
  flex-direction: row;
  margin-top: 1.2rem;
  margin-left: 2.6rem;
  margin-right: 2.4rem;
}

.label {
    vertical-align: middle;
    font-family: 'Pretendard';
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.671rem;
    color: #87888D;
}

.label-error {
  margin-left: auto;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.313rem;
  color: #FF3232;
}

.label-description {
  margin-left: auto;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.313rem;
  color: #9EA0A4;
}

.label-description-black {
  margin-left: auto;
  vertical-align: middle;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.313rem;
  color: #000000;
}

.input-field-container {
  margin-top: 0.7rem;
  margin-bottom: 1.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
}

.cellphone-padding {
  margin-left: 0.8rem;
}

.terms-container {
  margin-top: 2rem;
}

.terms-title {
  margin-left: 2.6rem;
  margin-bottom: 1.4rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.671rem;
  color: #87888D;
}

.terms-all-checked {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 2.6rem;
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: var(--color-black800);

  img {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.6rem;
  }
}

.terms-separate {
  width: calc(100% - 4rem);
  height: 1px;
  background-color: rgba(0, 0, 0, 0.07);
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.2rem;
}

.terms-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 2.6rem;
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.432rem;
  color: var(--color-black800);

  img {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.6rem;
  }
}

.terms-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.terms-view-content {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: auto;
  margin-right: 2.0rem;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.432rem;
  text-decoration: underline;
  color: #9A9CA2;
}

.terms-bottom-padding {
  margin-bottom: 3.2rem;
}

.register-button-container {  
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.sheet-terms-container {
  margin-top: 2.2rem;
  display: flex;
  flex-direction: row;

  img {
    cursor: pointer;
    margin-left: auto;
    margin-right: 2rem;
    width: 2.2rem;
    height: 2.2rem;
  }
}

.sheet-terms-title {
  margin-left: 2rem;
  margin-bottom: 0.4rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.52rem;
  color: var(--color-black800);
}

.sheet-terms-content {
  height: 4.14rem;
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black800);
}

.sheet-terms-button-container {
  background-color: white;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16)); 
  box-sizing: border-box;  
}

.sheet-terms-button {
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 12px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background: var(--color-green600);
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.6rem;
  color: white;
}

.popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 24px;
}

.popup-image {
  margin: 0;
  margin-bottom: 20px;
  width: 196px;
  height: 110px;
}

.popup-title {
  margin-bottom: 6px;
  font-family: 'Pretendard';
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.24rem;
  color: var(--color-black900);
}

.popup-content {
  margin-bottom: 20px;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: #888B93;
  white-space: pre-wrap;
}

.popup-button {
  width: 180px;
  height: 42px;
  background-color: #00B76A;
  border-radius: 24px;
  text-align: center;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 42px;
  color: #FFFFFF;
}