.container {
  border: 1px solid var(--color-green400);
  border-radius: 8px;
  margin: 1rem 1.6rem;
  font-family: 'Pretendard';
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-green50);
}
.text-container {
  padding: 1.2rem 1.4rem;
}
.text-container-main-text {
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0;
  width: auto;
  line-height: 2.1rem;
}
.text-container-sub-text {
  font-weight: normal;
  font-size: 1.1rem;
  color: var(--color-black_transparent_40);
  margin: 0;
  line-height: 1.82rem;
}
.sub-text-container {
  padding: 1.2rem 1.4rem;
  font-size: 1.3rem;
  font-weight: bold;
  color: var(--color-green600);
}

.sub-action-container {
  position: relative;
  font-weight: bold;
  font-size: 1.4rem;
  white-space: pre-wrap;
  color: var(--color-green600);
  border-radius: 0 8px 8px 0;
  border-left: 2px dotted var(--color-green500);
  padding: 2.4rem 2.1rem;
  background-color: var(--color-green50);

  img {
    margin: 0;
    padding: 0;
    width: 2.8rem;
    height: 2.8rem;
  }
}
