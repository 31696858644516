.rating-container {
  display: flex;
  align-items: center;
  span {
    font-size: 1.2rem;
    color: var(--color-green600);
    margin-right: 0.4rem;
    font-weight: 600;
  }
}

.info-container {
  margin: 1.2rem 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.info-item {
  padding: 1.2rem 0.8rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: var(--color-white50);
  border-radius: 6px;
  width: fit-content; /* 글자 길이에 맞춰 width 조정 */
}

.image-container {
  img {
    border-radius: 50%;
  }
}
