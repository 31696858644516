.container {
  font-family: 'Pretendard';
  margin: auto 2rem;
  white-space: pre-wrap;
}
.confirm-title {
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 1.1rem;
}

.confirm-text {
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 500;
}
.confirm-image-container {
  width: 100%;
  text-align: center;
  margin: 2.4rem auto;
  img {
    width: 16rem;
    height: 160px;
  }
}

.confirm-info {
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black200);
  margin-bottom: 1.2rem;
}
.confirm-support {
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.96rem;
  color: var(--color-black500);
  font-weight: 500;
  text-decoration: underline;
}
