.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.form-container {
  flex: 1;
  overflow-y: auto;
  box-sizing: box;
}

.button-container {
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.16));
  display: flex;
  width: 100%;
  height: 8rem;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.label-container {
  margin-top: 2.4rem;
  margin-left: 2rem;
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black800);
  white-space: pre-wrap;
}

.result-container {
  margin-top: 2.4rem;
}

.result-item {
  margin-left: 2.4rem;
  margin-right: 2rem;
  margin-bottom: 0.3rem;
  height: 4.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Pretendard';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.79rem;
  color: var(--color-black800);

  img {
    margin-right: 0rem;
  }
}

.result-item-selected {
  color: #00b76a;
}
@media (min-width: 1000px) {
  .container {
    min-width: 320px;
    width: 600px;
  }

}