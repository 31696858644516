.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.label-container {
  margin-top: 2.4rem;
  margin-bottom: 3rem;
  margin-left: 2rem;
  font-family: "Pretendard";
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--color-black900);
  /* white-space: pre-wrap; */
}
.label-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.check {
  margin-right: 3rem;
}
.text {
  margin-left: 2.2rem;
  vertical-align: middle;
  font-family: "Pretendard";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: var(--color-black500);
  white-space: pre-wrap;
  margin-bottom: 2.5rem;
}
.label {
  margin-left: 2.6rem;
  vertical-align: middle;
  font-family: "Pretendard";
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: #878893;
}
.input-field-container {
  margin-top: 0.7rem;
  margin-bottom: 3.2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: row;
}
